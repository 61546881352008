const defaultInitialState = {
	isLoading: false,
	data: null,
	error: null,
	loaded: false,
};

export const createEntityReducer = (entityName, defaultState = defaultInitialState) => {
	// Initial state for the entity
	const initialState = defaultState;

	// Action types
	const REQUEST = `${entityName}_REQUEST`;
	const SUCCESS = `${entityName}_SUCCESS`;
	const FAILURE = `${entityName}_FAILURE`;
	const RESET = `${entityName}_RESET`;

	// Reducer for thapie entity
	return (state = initialState, action) => {
		switch (action.type) {
			case REQUEST:
				return {
					...state,
					isLoading: true,
					error: null,
				};
			case SUCCESS:
				return {
					...state,
					data: action.payload,
					isLoading: false,
					error: null,
					loaded: true,
				};
			case FAILURE:
				return {
					...state,
					isLoading: false,
					error: action.payload,
					loaded: true,
				};
			case RESET:
				return initialState;
			default:
				return state;
		}
	};
};
