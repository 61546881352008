export const ERROR = 'ERROR';
export const USER = 'USER';
export const WHITELABEL = 'WHITELABEL';
export const USERDETAILS = 'USERDDETAILS';
export const CHECKLIST = 'CHECKLIST';
export const NC_STATUS = 'NC_STATUS';
export const UPLOAD_SELECTED_LOAN = 'UPLOAD_SELECTED_LOAN';
export const UPLOAD_DISBURSEMENT_DOC = 'UPLOAD_DISBURSEMENT_DOC';
export const SETTING_USER_ID = 'SETTING_USER_ID';
export const SETTING_SANCTION_AMOUNT = 'SETTING_SANCTION_AMOUNT';
export const SETTING_SANCTION_AMOUNT_UM = 'SETTING_SANCTION_AMOUNT_UM';
export const LOAN_LIST = 'LOAN_LIST';
export const LOAN_LIST_UPDATE = 'LOAN_LIST_UPDATE';
export const LOAN_APPROVAL  = 'LOAN_APPROVAL';
