import CryptoJS from 'crypto-js';
import {
	axiosAPI,
	axiosAPIVerification,
	getApiServiceUrl,
	axiosOriginAPI,
	axiosCommonServicesAPI,
} from '../../shared/utils/helper/baseUrls';
import {
	ERROR,
	CHECKLIST,
	NC_STATUS,
	UPLOAD_SELECTED_LOAN,
	UPLOAD_DISBURSEMENT_DOC,
	SETTING_USER_ID,
	SETTING_SANCTION_AMOUNT,
	SETTING_SANCTION_AMOUNT_UM,
	LOAN_LIST,
	LOAN_LIST_UPDATE,
} from '../actionTypes';
import { secret } from '../../shared/utils/helper/getWhitelabel';
import { message } from 'antd';
import axios from 'axios';
import { CUSTOMER_FETCH_API_END_POINT } from '../../_config/app.config';
import { fakeAuth } from '../../shared/utils/helper/RouteHelper';
import { decryptString } from '../..//utils/encrypt';
const VIEW_LOAN_LISTING_LIMIT = 10;

export function getLoanProducts() {
	return dispatch =>
		axiosAPI()
			.get('/loanproducts')
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'Something went wrong!' });
			});
}

export function generateTokenWithLoanRefId(loanRefId) {
	return dispatch =>
		axiosAPI()
			.get(`/generate_token_basedOn_loanref_id?loan_ref_id=${loanRefId}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'Something went wrong!' });
			});
}

export function getLoanProductDetails(id) {
	return dispatch =>
		axiosAPI()
			.get(`/loanproducts/${id}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getAssets(payload) {
	return dispatch =>
		axiosAPI()
			.get(`/assets_details`, {
				params: payload,
			})
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function submitSanctionedAssets(payload) {
	return dispatch =>
		axiosAPI()
			.post(`/sanction_assets_data`, payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getEDIUpdatedDetails(payload) {
	return dispatch =>
		axiosAPI()
			.get(`/JSON_compare`, {
				params: payload,
			})
			.then(res => {
				return res.data.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function postApprovalFlow(payload) {
	return dispatch =>
		axiosAPI()
			.post(`/updated_json`, payload)
			.then(res => {
				return res;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getEDIData(payload) {
	return dispatch =>
		axiosAPI()
			.get(`/edi_fetch`, {
				params: payload,
			})
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function postEDIAcceptReject(payload) {
	return dispatch =>
		axiosAPI()
			.post(`/accept_reject`, payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				message.error(err.message);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function fetchThirdPartyResponse(payload) {
	return dispatch =>
		axiosAPI()
			.get(`/prefetch_status_fetch`, { params: payload })
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				message.error(err.message);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function fetchEmiDetails(payload) {
	return dispatch =>
		axiosAPI()
			.get(`/emi`, { params: payload })
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function fetchLoanEnachStatus(payload) {
	return dispatch =>
		axiosAPI()
			.get(`/get_enach_status`, { params: payload })
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function ChangeEmandateStatus(payload) {
	return dispatch =>
		axiosAPI()
			.post(`/Enach/change_mandate_type`, payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function eNachStatusCheck(payload) {
	return dispatch =>
		axiosAPI()
			.get(`/get_mandate_status`, { params: payload })
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				message.error(err.message);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function cancelEmandate(payload) {
	return dispatch =>
		axiosAPI()
			.get(`/cancel_mandate`, { params: payload })
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				message.error(err.message);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function inititateEnach(payload) {
	return dispatch =>
		axiosAPI()
			.post(`/create_mandate`, payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				message.error(err.message);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getDocumentTypes(loan_product, business_type) {
	let excl_doc_types = [];
	excl_doc_types = process.env.REACT_APP_EXCLUDE_DOC_TYPES && process.env.REACT_APP_EXCLUDE_DOC_TYPES.toString();

	return dispatch =>
		axiosAPI()
			.post(`/loan/documentTypes/`, {
				loan_product: loan_product,
				business_type: business_type,
				excluded_doc_type_ids: excl_doc_types,
			})
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getAllDocumentTypes(payload) {
	return dispatch =>
		axiosAPI()
			.post(`/loan/documentTypes/`, payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function getCoAppDocumentTypes(income_type) {
	return dispatch =>
		axiosAPI()
			.get(`/coApplicantDocList?income_type=${income_type}`)
			.then(res => {
				return res.data.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function openDocument(reqBody) {
	return dispatch =>
		axiosAPI()
			.post(`/viewDocument`, reqBody)
			.then(docRes => {
				return docRes;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function viewDocumentOnlyOffice(reqBody) {
	return dispatch =>
		axiosAPI()
			.post(`/upload_document_ml`, reqBody)
			.then(docRes => {
				// console.log('openDocument-docRes', docRes);
				// window.open(decryptViewDocumentUrl(docRes.data.signedurl), '_blank');
				return docRes;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function fetchOnlyOfficeDocUrl(reqBody) {
	return dispatch =>
		axiosAPI()
			.post(`/editOnlineDocument`, reqBody)
			.then(docRes => {
				return docRes.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function updateOfficeEditedFile(reqBody) {
	return dispatch =>
		axiosAPI()
			.post(`/upload_document_ml`, reqBody)
			.then(docRes => {
				// console.log('openDocument-docRes', docRes);
				// window.open(decryptViewDocumentUrl(docRes.data.signedurl), '_blank');
				return docRes;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function submitDocCehckList(reqBody) {
	return dispatch =>
		axiosAPI()
			.post(`/docCheckList`, reqBody)
			.then(res => {
				// console.log('submitDocCehckList-res', res);
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function validateStatus(reqBody) {
	return dispatch =>
		axiosAPI()
			.post(`/validate_case`, reqBody)
			.then(res => {
				// console.log('submitDocCehckList-res', res);
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function validateAdditionalVerificationStages(reqBody) {
	return dispatch =>
		axiosOriginAPI()
			.get(`/additional-verification/api/get-completed-forms`, {
				params: reqBody,
			})
			.then(res => {
				// console.log('submitDocCehckList-res', res);
				return res.data;
			})
			.catch(err => {
				console.error(err);
				message.error(err.message);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function populateTemplate(reqBody) {
	return dispatch =>
		axiosAPI()
			.post('/populateTemplate/excel', reqBody)
			.then(res => {
				//console.error('response from populateTemplate', res);
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function openSpreadSheet(reqBody) {
	return dispatch =>
		axiosAPI()
			.post('/zsheet', reqBody)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function uploadDocument(reqBody) {
	return dispatch =>
		axiosAPI()
			.post('/loanDocumentUpload', reqBody)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function uploadDocumentAndEdit(reqBody) {
	return dispatch =>
		axiosAPI()
			.post('/edited_document_save', reqBody)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getLoanProductType(id) {
	return dispatch =>
		axiosAPI()
			.get(`/loanproducttype?loan_product_id=${id}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function doCheckInList(id) {
	return dispatch => {
		dispatch({ type: CHECKLIST, payload: id });
	};
}

export function viewLoanListing(skip, searchValue, canceleToken) {
	let search = searchValue === undefined ? '' : searchValue;
	return (dispatch, getState) =>
		axiosAPI()
			.get(`/viewloanlisting?status1=1&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`, {
				cancelToken: canceleToken,
			})
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					// Temporory disabled for performance issue
					// let loanList = getState()['loan'].loanList;
					// loanList.forEach(i => {
					// 	getPendingDocCounts(i, getState, dispatch);
					// });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function viewLoanListingDisbursed(skip) {
	let status = {
		status1: 2,
		status2: 9,
		status3: 12,
		status4: 12,
	};
	return dispatch =>
		axiosAPI()
			.get(
				`/loanlisting?skip=${skip}&limit=15&status1=${status.status1}&status2=${status.status2}&status3=${status.status3}&status4=${status.status4}`
			)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
			});
}

export function getloanBankMapping(id) {
	return dispatch =>
		axiosAPI()
			.get(`/loanbankmappingdetails?loan_id=${id}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getLoanSanction(loanid, loan_bank_mapping_id) {
	return dispatch =>
		axiosAPI()
			.get(`/loansanction/?loan_id=${loanid}&loan_bank_mapping_id=${loan_bank_mapping_id}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getXLSDocumentUrl(loan_ref_id, doc_type_id, loan_id, userid) {
	return dispatch =>
		axiosAPI()
			.post(
				`/populateTemplate/excel?loan_ref_id=${loan_ref_id}&doc_type_id=${doc_type_id}&loan_id=${loan_id}&userid=${userid}`
			)
			.then(res => {
				if (res && res.status === 200) {
					window.open(res.data.document_url, '_blank');
				}
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getNCStatusManage() {
	const user = localStorage.getItem('user');

	const whiteLabelId = JSON.parse(decryptString(user)).white_label.id || '';
	return dispatch =>
		axiosAPI()
			.get(`/ncstatusmanage?white_label_id=${whiteLabelId}`)
			.then(res => {
				dispatch({ type: NC_STATUS, payload: res.data });
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function viewLoanApplication(id) {
	return dispatch =>
		axiosAPI()
			.get(`/loanrequest/${id}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function applyLoan(loan) {
	let permission = fakeAuth.getPermission().dashboard;
	return dispatch =>
		axiosAPI()
			.post(permission.case_creation === true ? '/casecreation_uiux' : '/loan/createloan', loan)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err;
			});
}

export function getSummaryData(loanId) {
	return axiosAPI()
		.get(`/jsonExtractionFetch?loanId=${loanId}`)
		.then(res => res)
		.catch(err => {
			console.error(err);
		});
}

export function applicantOrEntityDocTypes(reqBody) {
	return axiosAPI()
		.post(`/loan/documentTypes/`, reqBody)
		.then(res => {
			return res;
		})
		.catch(err => {
			console.error(err);
			// dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			return err.response;
		});
}

export function getCoapplicantDocTypes(incomeTypeArr) {
	return axiosAPI()
		.get(`/coApplicantDocList?income_type=${incomeTypeArr.join(',')}`)
		.then(res => res)
		.catch(err => {
			console.error(err);
		});
}

export function getUploadedDocuments(loanRefId) {
	return axiosAPI()
		.get(`/UploadedDocList_uiux?loan_ref_id=${loanRefId}`)
		.then(res => res)
		.catch(err => {
			console.error(err);
		});
}

export function getProfiledata() {
	return dispatch =>
		axiosAPI()
			.get(`/profile/`)
			.then(res => {
				return res.data;
			})

			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function verificationFetch(loanListItem) {
	let businessId = '';
	let loan_ref_id = '';
	let loan_id = '';
	if (loanListItem && loanListItem.business && loanListItem.business.id) businessId = loanListItem.business.id;
	if (loanListItem && loanListItem.loan && loanListItem.loan.loan_ref_id) loan_ref_id = loanListItem.loan.loan_ref_id;
	if (loanListItem && loanListItem.loan && loanListItem.loan.id) loan_id = loanListItem.loan.id;
	return axiosAPIVerification()
		.get(`/verificationDataApi_uiux?businessId=${businessId}&loan_ref_id=${loan_ref_id}&loan_id=${loan_id}`, {
			headers: { Authorization: ` ${localStorage.getItem('token')}` },
		})
		.then(res => {
			return res.data.data;
		})
		.catch(err => {
			console.error(err);
		});
}
export async function directorDetailsFetch(directorId, loanListItem) {
	let businessId = '';
	if (loanListItem && loanListItem.business && loanListItem.business.id) businessId = loanListItem.business.id;
	if (!!directorId) {
		const user = fakeAuth.getUser() || {};
		const whiteLabel = JSON.parse(localStorage.getItem('wt_lbl')) || {};
		try {
			const res = await axios.post(`${getApiServiceUrl()}/sails-exp/ClientVerify`, {
				email: user.email,
				white_label_id: whiteLabel.id,
			});
			if (res.data && res.data.status && res.data.status === 'ok') {
				try {
					const res2 = await axios.post(
						`${getApiServiceUrl()}/equifax/fetchDataNPlusOne/`,
						{ director_id: directorId, business_id: businessId },
						{
							headers: {
								Authorization: res.data.token,
							},
						}
					);
					if (res && res.data && res.data.status === 'ok') {
						// message.success(res2.data.message);
						return res2.data;
					}
				} catch (err) {
					console.error(err);
				}
				// .then(res2 => {
				// 	console.log('Second Api called', res2);
				// 	message.success(res2.data.message);
				// 	return res2.data;
				// })
			}
		} catch (err_1) {
			console.error(err_1);
		}
	}
}
export function viewDocument(docName, userid, loanId, isProfile) {
	return dispatch =>
		axiosAPI()
			.post(`/viewDocument/`, { filename: docName, userid: userid, loan_id: loanId, isProfile: isProfile })
			.then(res => {
				const t = data => {
					var rawData = CryptoJS.enc.Base64.parse(data);
					var key = CryptoJS.enc.Latin1.parse(secret);
					var iv = CryptoJS.enc.Latin1.parse(secret);
					var plaintextData = CryptoJS.AES.decrypt({ ciphertext: rawData }, key, { iv: iv });
					var plaintext = plaintextData.toString(CryptoJS.enc.Latin1);
					res.data.signedurl = plaintext;
				};
				t(res.data.signedurl);
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function updateDisbursement(obj) {
	return dispatch =>
		axiosAPI()
			.post(`/loandisbursement/update/`, obj)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function fetchCibil(obj, endpoint) {
	return dispatch =>
		axiosAPIVerification()
			.post(`${endpoint}`, obj)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function loanDocumentUpload(obj) {
	return dispatch =>
		axiosAPIVerification()
			.post(`/fetchCibil`, obj)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function uploadGeoLocationImage(obj) {
	return dispatch =>
		axiosAPI()
			.post(`/geo/upload_img`, obj)
			.then(res => {
				return res;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}
export function changeStatusTLtoAR(obj) {
	return dispatch =>
		axiosAPI()
			.post(`/changeStatusTLtoAR`, obj)
			.then(res => {
				return res;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}
export function changeProduct(obj) {
	return dispatch =>
		axiosAPI()
			.post(`/product_update`, obj)
			.then(res => {
				return res;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}
export function LeadsAddCommentsAndMoveToDraft(obj) {
	return dispatch =>
		axiosAPI()
			.post(`/lead_cta_change_and_addComments`, obj)
			.then(res => {
				return res && res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}
export function updateloanType(obj) {
	return dispatch =>
		axiosAPI()
			.post(`/update_loan_type`, obj)
			.then(res => {
				return res;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}
//api to submit and save the xlsx file
export function submitSpreadSheet(obj) {
	return dispatch =>
		axiosAPI()
			.post(`/uploadExcel`, obj)
			.then(res => {
				return res;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}

//api to delete the file from the zoho server after any spreadsheet changes
export function deleteSpreadSheet(obj) {
	return dispatch =>
		axiosAPI()
			.post(`/deleteSpreadsheet`, obj)
			.then(res => {
				return res;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}

export function updateSanction(obj) {
	return dispatch =>
		axiosAPI()
			.post(`/loansanction/update`, obj)
			.then(res => {
				return res;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}
export function regenerateSanctionLetterLenderDocs(obj) {
	return dispatch =>
		axiosAPI()
			.post(`/sanction_letter_regenerate`, obj)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response || err;
			});
}
export function regenerateSanctionLetter(obj) {
	return axiosAPI()
		.post(`/sanction_letter_regenerate`, obj)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			console.error(err);
			// dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			return err.response || err;
		});
}
export function regenerateApplicationPdf(obj) {
	return dispatch =>
		axiosAPI()
			.post(`/application_json_regeration`, obj)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				// dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response || err;
			});
}
export function getOnSiteVerificationLoanData(obj) {
	return dispatch =>
		axiosAPI()
			.post(`loan_details`, obj)
			.then(res => {
				return res;
			})
			.catch(err => {
				console.error(err);
				// dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}
export function getAddressByLatLong(obj) {
	return dispatch =>
		axiosAPI()
			.post(`geoLocation`, obj)
			.then(res => {
				return res;
			})
			.catch(err => {
				console.error(err);
				// dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}

export function lenderDocumentDelete(obj) {
	return dispatch =>
		axiosAPI()
			.post(`lenderDocumentDelete`, obj)
			.then(res => {
				return res;
			})
			.catch(err => {
				console.error(err);
				// dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}

export function getEmi(obj) {
	return dispatch =>
		axiosAPI()
			.post(`/giveOfferEligibility`, obj)
			.then(res => {
				return res;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}

export function getBpiAmount(reqBody) {
	return dispatch =>
		axiosAPI()
			.post(`/get-bpi`, reqBody)
			.then(res => {
				return res;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}

export function uploadSelectedLoan(selectedLoan) {
	return dispatch => {
		dispatch({ type: UPLOAD_SELECTED_LOAN, payload: selectedLoan });
	};
}

export function uploadedDisbursementDocuments(documentsArray) {
	return dispatch => {
		dispatch({ type: UPLOAD_DISBURSEMENT_DOC, payload: documentsArray });
	};
}

export function settingUserId(userId) {
	return dispatch => {
		dispatch({ type: SETTING_USER_ID, payload: userId });
	};
}

export function settingSanctionAmount(san_amt) {
	return dispatch => {
		dispatch({ type: SETTING_SANCTION_AMOUNT, payload: san_amt });
	};
}
export function settingSanctionAmountUnit(san_amt_um) {
	return dispatch => {
		dispatch({ type: SETTING_SANCTION_AMOUNT_UM, payload: san_amt_um });
	};
}

export function downloadSheetXLS(loanId) {
	try {
		axiosAPI()
			.get(`/PDF-XLS_download`, { params: { loanId } })
			.then(res => {
				if (res && res.status === 200) {
					window.open(res.data.url.xlsxUrl);
				}
			});
	} catch (err) {
		message.error(err.message);
	}
}

export function downloadSheetPDF(loanId) {
	try {
		axiosAPI()
			.get(`/PDF-XLS_download`, { params: { loanId } })
			.then(res => {
				if (res && res.status === 200) {
					window.open(res.data.url.pdfUrl);
				}
			});
	} catch (err) {
		message.error(err.message);
	}
}
export function getWordDocumentData(params) {
	const { loan_id, loan_ref_id } = params;
	return dispatch =>
		axiosAPI()
			.post(`/editWord`, { loan_id, loan_ref_id })
			.then(res => {
				// console.log({ res });
				if (res && `${res.status}` === '200') {
					return res.data;
				}
			})
			.catch(err => {
				console.error(err);
				// dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function submitWordDocument({ url }) {
	return dispatch =>
		axiosAPI()
			// .post(`/submitWord`, reqBody)
			.post(`/save_url`, { url })
			.then(res => {
				return res;
			})
			.catch(err => {
				// dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response || err;
			});
}

export function viewLoan(id) {
	return axiosAPI()
		.get(`/loanrequest/${id}`)
		.then(res => {
			return res.data;
		})
		.catch(err => {
			console.error(err);
			// dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
		});
}
export function getLenderDocuments() {
	// console.log('getlenderdocs-exclude-', process.env.REACT_APP_EXCLUDE_DOC_TYPES);
	let params = '';
	if (process.env.REACT_APP_EXCLUDE_DOC_TYPES) {
		params += `?excluded_doc_type_ids=${process.env.REACT_APP_EXCLUDE_DOC_TYPES.toString().replaceAll('-', ',')}`;
	}
	return dispatch =>
		axiosAPI()
			.get(`/lender/doctype${params}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getLenderDocs(loan_product_id) {
	// excluding geo-location related documents
	let params = '';
	if (process.env.REACT_APP_EXCLUDE_DOC_TYPES) {
		params += `excluded_doc_type_ids=${process.env.REACT_APP_EXCLUDE_DOC_TYPES.toString().replaceAll('-', ',')}`;
	}
	return dispatch =>
		axiosAPI()
			.get(`/lender/doctype?product_id=${loan_product_id}&${params}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function uploadBorrowerDocuments(obj, type, loanId) {
	return (dispatch, getState) =>
		axiosAPI()
			.post(`/borrowerdoc-upload`, { upload_document: obj })
			.then(res => {
				if (type === 'DB_BANK_UPLOAD') {
					let loanList = getState()['loan'] && getState()['loan'].loanList && getState()['loan'].loanList;
					let index = loanList.findIndex(item => item.id === loanId);
					if (index !== -1) {
						loanList[index]['dbDocCheck'] = 'Uploaded';
						dispatch({ type: LOAN_LIST_UPDATE, payload: loanList });
						return res.data;
					}
				} else {
					return res.data;
				}
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function deleteLenderDocuments(req, loan_id) {
	return (dispatch, getState) =>
		axiosAPI()
			.post(`/documentDelete`, req)
			.then(res => {
				let loanList = getState()['loan'] && getState()['loan'].loanList && getState()['loan'].loanList;
				let index = loanList.findIndex(item => item.id === req.loan_id);
				if (index !== -1) {
					loanList[index]['dbDocCheck'] = 'Pending';
					dispatch({ type: LOAN_LIST_UPDATE, payload: loanList });
					return res.data;
				}
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function uploadLenderDocuments(obj) {
	return dispatch =>
		axiosAPI()
			.post(`/lenderdoc-upload`, { upload_document: obj })
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function uploadOtherUserDocuments(obj) {
	return dispatch =>
		axiosAPI()
			.post(`/otherUserDoc-upload`, { upload_document: obj })
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function editLoans(loan) {
	return dispatch =>
		axiosAPI()
			.post(`/loan/edit`, loan)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function acceptRejectLoans(status, loan_bank_mapping_id, loan_id) {
	return dispatch =>
		axiosAPI()
			.post(`/loanAcceptReject`, {
				status: status,
				loan_bank_mapping_id: loan_bank_mapping_id,
				loan_id: loan_id,
			})
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function removeDocuments(document) {
	return dispatch =>
		axiosAPI()
			.post(`/documentDelete`, document)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				console.error(err);
				// dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function viewInProgressLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(`/viewloanlisting?status1=2&status2=8&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function viewTlReviewLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(
				`/viewloanlisting?status1=2&status2=9&status3=9,12&status4=2,10&status6=0&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`
			)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function viewAnalystReviewLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(
				`/viewloanlisting?status1=2&status2=9&status3=12&status4=10&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`
			)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function viweNotQualifiedLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(`/viewloanlisting?status1=7&status2=13&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function searchLoanListing(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(`/viewloanlisting?skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function viewCreditAssignedLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(
				`/viewloanlisting?status1=2&status2=9&status3=9,12&status4=2,3,10&status5=1&status6=0&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`
			)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function viewAssignedLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(
				`/viewloanlisting?status1=2&status2=9&status3=9,12&status4=2,3,10&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`
			)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

// function for API call for fetching IMD details for give offer and sanction related actions
export function fetchImdDetails(data) {
	const { loan_id } = data;
	return dispatch =>
		axiosAPI()
			.get(`imdDetails?loan_id=${loan_id}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function fetchCollateralDetails(data) {
	const { loan_id } = data;
	return dispatch =>
		axiosAPI()
			.post(`/getCollateralValue`, { loan_id })
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function validateUdyam(data) {
	const { business_id, loan_product_id } = data;
	return dispatch =>
		axiosAPI()
			.get(`validate_udyam?business_id=${business_id}&loan_product_id=${loan_product_id}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function fetchCamDocSanctionDetails(data) {
	const { loan_id, doc_type_id } = data;
	return dispatch =>
		axiosAPI()
			.get(`getCamJson?loan_id=${loan_id}&doc_type_id=${doc_type_id}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getDirectorMisStatus(data) {
	const { loan_id } = data;
	return dispatch =>
		axiosAPI()
			.get(`/get_director_status?loan_id=${loan_id}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function getInsuranceSubmissionStatus(loan_id) {
	return dispatch =>
		axiosAPI()
			.get(`/insurance/penetration-submission/status?loan_id=${loan_id}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function getDirectorUCICStatus(data) {
	return dispatch =>
		axiosAPI()
			.get(`/validate_ucic`, { params: data })
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function postGiveOffer(value) {
	return dispatch =>
		axiosAPI()
			.post('giveOffer', value)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function addComments(obj) {
	return dispatch =>
		axiosAPI()
			.post('/addComments', obj)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				// dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function replyToQueryComment(obj) {
	return dispatch =>
		axiosAPI()
			.post('/query_reply', obj)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
// This is used for move to credit review and raise query to branch
export function moveCase(obj) {
	return dispatch =>
		axiosAPI()
			.post('/move_case', obj)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				// dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function updateFederalBiometric(payload) {
	return dispatch =>
		axios
			.post(`${CUSTOMER_FETCH_API_END_POINT}/kyc_pull`, payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getDirectorsList(loanRefId, businessId) {
	return dispatch =>
		axiosAPI()
			.get(`/getDetails?loan_ref_id=${loanRefId}&business_id=${businessId}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function createCustomerId(payload) {
	return dispatch =>
		axios
			.post(`${CUSTOMER_FETCH_API_END_POINT}/cif_creation`, payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function updateClapsId(payload) {
	return dispatch =>
		axios
			.post(`${CUSTOMER_FETCH_API_END_POINT}/CLAPS/customer_id_update`, payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function fetchClapsId(loanId) {
	return dispatch =>
		axios
			.get(`${CUSTOMER_FETCH_API_END_POINT}/CLAPS/loan_creation?loan_id=${loanId}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function createCbsLoan(payload) {
	return dispatch =>
		axios
			.post(`${CUSTOMER_FETCH_API_END_POINT}/loan_creation`, payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function updateCustomerId(payload) {
	return dispatch =>
		axiosAPI()
			.post('/update_customer_id', payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function addDisbursment(addDisbursementData) {
	return dispatch =>
		axiosAPI()
			.post('/add_Disbursement', addDisbursementData)
			.then(res => {
				if (res && res.data) {
					return res.data;
				}
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function scheduleMeeting(meetingData) {
	return (dispatch, getState) =>
		axiosAPI()
			.post('/meetingSchedule', meetingData)
			.then(res => {
				let loanList = getState()['loan'] && getState()['loan'].loanList && getState()['loan'].loanList;
				let index = loanList.findIndex(item => item.id === res.data.data[0]['loan_id']);
				if (index !== -1) {
					loanList[index]['loan_bank_mapping'] = res.data.data[0]['loan_bank_mapping'];
					loanList[index].isCardOpen = !loanList[index].isCardOpen;
					dispatch({ type: LOAN_LIST_UPDATE, payload: loanList });
				}
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function acceptMeeting(acceptMeetingData) {
	return (dispatch, getState) =>
		axiosAPI()
			.post('/acceptMeeting', acceptMeetingData)
			.then(res => {
				let loanList = getState()['loan'] && getState()['loan'].loanList && getState()['loan'].loanList;
				let index = loanList.findIndex(item => item.id === res.data.data[0]['loan_id']);
				if (index !== -1) {
					loanList[index]['loan_bank_mapping'] = res.data.data[0]['loan_bank_mapping'];
					dispatch({ type: LOAN_LIST_UPDATE, payload: loanList });
				}

				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function lenderStatus() {
	return dispatch =>
		axiosAPI()
			.get('lenderStatus')
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function changeLenderStatus(data) {
	return (dispatch, getState) =>
		axiosAPI()
			.post('changeLenderStatus', data)
			.then(res => {
				let loanList = getState()['loan'].loanList;
				loanList.forEach(item => {
					if (item.loan_bank_mapping.id === res.data['loanbankmapping'].id) {
						let index = loanList.indexOf(item);
						loanList[index].loan_bank_mapping = res.data['loanbankmapping'];
						loanList[index].loan_bank_mapping.lender_status = res.data['loanbankmapping'].lender_status;
						loanList[index].loan.remark_history = res.data['loanstatuscomments'].comment_text;
						dispatch({ type: LOAN_LIST_UPDATE, payload: loanList });
					}
				});
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function clearLoanList() {
	return dispatch => dispatch({ type: LOAN_LIST_UPDATE, payload: [] });
}
export function viewLeadsSection(skip = 0) {
	return dispatch =>
		axiosAPI()
			.get(`/leadlist?skip=${skip}`)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: 'Leads', payload: res.data });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function getCommentsListForLeads(leadId) {
	return dispatch =>
		axiosAPI()
			.get(`/lead_comments_fetch?lead_id=${leadId}`)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: 'Leads', payload: res.data });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function viewQueryRaisedLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(
				`/viewloanlisting?status1=8,15,16&status2=12,15,16&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`
			)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function viewBpoInitiatedLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(`/viewloanlisting?status1=2&status2=7&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function viewPendingLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(`/viewloanlisting?status=Pending&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function viewRework(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(`/viewloanlisting?status=Rework&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function viewSentBack(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(`/viewloanlisting?status=Sentback&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function viewCompletedLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(
				`/viewloanlisting?status1=2&status2=9&status3=9&status4=2&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`
			)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

// export function viewBranchReviewLoans(skip, searchValue) {
// 	let search = searchValue === undefined ? '' : searchValue;
// 	return dispatch =>
// 		axiosAPI()
// 			.get(
// 				`/viewloanlisting?status1=2&status2=9&status3=12&status4=10&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`
// 			)
// 			.then(res => {
// 				if (res && res.data) {
// 					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
// 					return res.data;
// 				}
// 				return null;
// 			})
// 			.catch(err => {
// 				console.error(err);
// 				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
// 			});
// }

// export function viewRcuReviewLoans(skip, searchValue) {
// 	let search = searchValue === undefined ? '' : searchValue;
// 	return dispatch =>
// 		axiosAPI()
// 			.get(
// 				`/viewloanlisting?status1=2&status2=9&status3=12&status4=10&status5=1&status6=1&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`
// 			)
// 			.then(res => {
// 				if (res && res.data) {
// 					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
// 					return res.data;
// 				}
// 				return null;
// 			})
// 			.catch(err => {
// 				console.error(err);
// 				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
// 			});
// }
export function viewReviewLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(
				`/viewloanlisting?status1=2&status2=9&status3=12&status4=10&status6=1,2&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`
			)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function viewCreditReviewLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(
				`/viewloanlisting?status1=2&status2=9&status3=12&status4=10&status5=1&status6=2&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`
			)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function viewRejectedLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return dispatch =>
		axiosAPI()
			.get(
				`/viewloanlisting?status1=2&status2=9&status3=12,14
		&status4=11,7&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`
			)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function initiateDocument(comments, case_id, status) {
	return (dispatch, getState) => {
		let loanList = getState()['loan'].loanList;
		//console.log('loanlist initiate', loanList, case_id);
		loanList.forEach(item => {
			if (item.loan.loan_ref_id === case_id) {
				item.isInitiateLoading = true;
				dispatch({ type: LOAN_LIST_UPDATE, payload: loanList });
			}
		});
		return axiosAPI()
			.post(`casecreation-docStatus_initiate`, {
				case_id,
				status,
				comments,
			})
			.then(res => {
				if (res && res.data) {
					loanList.forEach(item => {
						if (item.loan.loan_ref_id === res.data.data.loan_ref_id) {
							let index = loanList.indexOf(item);
							loanList[index].loan.document_upload = 'Done';
							loanList[index].isInitiateLoading = false;
							dispatch({ type: LOAN_LIST_UPDATE, payload: loanList });
						}
					});
					return res.data;
				}
			})
			.catch(err => {
				loanList.forEach(item => {
					if (item.loan.loan_ref_id === case_id) {
						item.isInitiateLoading = false;
						dispatch({ type: LOAN_LIST_UPDATE, payload: loanList });
					}
				});
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response.data;
			});
	};
}

export function notQualified(comments, loan_id, status, loan_bank_mapping_id, reason) {
	return (dispatch, getState) => {
		// let loanList = getState()['loan'].loanList;
		// loanList.forEach(item => {
		// 	if (item.loan.loan_ref_id === case_id) {
		// 		dispatch({ type: LOAN_LIST_UPDATE, payload: loanList });
		// 	}
		// });
		return axiosAPI()
			.post(`reject_notQualify_loan`, {
				loan_id,
				status,
				comments,
				loan_bank_mapping_id,
				reason,
			})
			.then(res => {
				// console.log('res', res);
				if (res && res.data) {
					return res.data;
				}
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response.data;
			});
	};
}
export function restorePrevoiusStage(comments, loan_id) {
	return (dispatch, getState) => {
		return axiosAPI()
			.post(`restore_previous_case`, {
				loan_id,
				comments,
			})
			.then(res => {
				// console.log('res', res);
				if (res && res.data) {
					return res.data;
				}
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response.data;
			});
	};
}

export function viewReassignedLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return (dispatch, getState) =>
		axiosAPI()
			.get(
				`/viewloanlisting?status1=2&status2=9&status3=10&status4=4&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`
			)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					// Temporory disabled for performance issue
					// let loanList = getState()['loan'].loanList;
					// loanList.forEach(i => {
					// 	getPendingDocCounts(i, getState, dispatch);
					// });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function viewDraftLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return (dispatch, getState) =>
		axiosAPI()
			.get(`/viewloanlisting?status1=1&status2=1&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function viewOtherUserAssignedLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return (dispatch, getState) =>
		axiosAPI()
			.get(`/viewloanlisting?status=Assigned&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					// Temporory disabled for performance issue
					// let loanList = getState()['loan'].loanList;
					// loanList.forEach(i => {
					// 	getPendingDocCounts(i, getState, dispatch);
					// });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function viewOtherUserEvaluationCompletedLoans(skip, searchValue) {
	let search = searchValue === undefined ? '' : searchValue;
	return (dispatch, getState) =>
		axiosAPI()
			.get(`/viewloanlisting?status=EvaluationCompleted&skip=${skip}&limit=${VIEW_LOAN_LISTING_LIMIT}&search=${search}`)
			.then(res => {
				if (res && res.data) {
					dispatch({ type: LOAN_LIST, payload: res.data.loan_details });
					// Temporory disabled for performance issue
					// let loanList = getState()['loan'].loanList;
					// loanList.forEach(i => {
					// 	getPendingDocCounts(i, getState, dispatch);
					// });
					return res.data;
				}
				return null;
			})
			.catch(err => {
				console.error(err);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getManager(loanId) {
	return dispatch =>
		axiosAPI()
			.post('/creditManagerList', { loanId })
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function getManagerForDisbursement(loanBankMappingId) {
	return dispatch =>
		axiosAPI()
			// .get('/OPS/user_list')
			.get(`/OPS/user_list?loan_bank_mapping_id=${loanBankMappingId}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getCMManager(loanId) {
	return dispatch =>
		axiosAPI()
			.get(`/creditUserListNew?loanId=${loanId}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function updateCreditManager(loanId, manager_userid, emails, assignto = '') {
	return (dispatch, getState) =>
		axiosAPI()
			.post('/updateCreditManager', { loanId, manager_userid, emails: emails ? emails : [], assignto })
			.then(res => {
				let loanList = getState()['loan'].loanList;
				loanList.forEach(item => {
					if (item.loan.id === loanId) {
						item.sales_id = res.data.data;
						dispatch({ type: LOAN_LIST_UPDATE, payload: loanList });
					}
				});
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function updateBpoAssignee(data) {
	let token = localStorage.getItem('token');
	return dispatch =>
		axiosAPI()
			.post(`/evaluatorReassign`, data, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(res => {
				return res.data;
			})
			.catch(err => {
				return err;
			});
}

export function reAssignedToNc(reqBody) {
	return (dispatch, getState) =>
		axiosAPI()
			.post('/loanReassignToNc', reqBody)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getEncryptedWL(loanId, loanBankId, comments) {
	return (dispatch, getState) =>
		axiosAPI()
			.get('/case-whitelabelEncrypt')
			.then(async res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getAnalyzerDocument(caseId, wid) {
	return (dispatch, getState) =>
		axiosAPI()
			.get(`/uploaded_doc_list?case_id=${caseId}&white_label_id=${wid}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response.data;
			});
}

export function downloadZipDocuments(userid, loandocs) {
	return (dispatch, getState) =>
		axiosAPI()
			.post(`/getzipdocument`, { userid, loandocs, tag: 'loan_doc' })
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response.data;
			});
}

export function getLoanCounts(userid, loandocs) {
	return (dispatch, getState) =>
		axiosAPI()
			.get(`/loanStatusCounts`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response.data;
			});
}

// Temporory disabled for performance issue
// TODO: find alternate solution for this
// eslint-disable-next-line
function getPendingDocCounts(loan, getState, dispatch) {
	let reqBody = {
		loan_id: loan.loan.id,
		loan_ref_id: loan.loan.loan_ref_id,
		loan_documents: loan.loan_document,
		businessType: loan.business.businesstype,
		product_id: loan.loan_products.id,
	};
	axiosAPI()
		.post(`/pendingCount`, reqBody)
		.then(res => {
			if (res.data.status === 'ok') {
				let loanList = getState()['loan'] && getState()['loan'].loanList && getState()['loan'].loanList;
				let index = loanList.findIndex(item => item.loan.loan_ref_id === res.data.data['loan_ref_id']);
				if (index !== -1) {
					loanList[index].pending_doc_count = res.data.data['pendingDoc_count'];
					dispatch({ type: LOAN_LIST_UPDATE, payload: loanList });
					return;
				}
				return;
			}
			return;
		})
		.catch(err => {
			dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			return true;
		});
}

export function updateCinRoc(data) {
	return dispatch =>
		axiosAPI()
			.post(`/cin-update`, data)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response.data;
			});
}

export function checkCaseCreated(cin) {
	return dispatch =>
		axiosAPI()
			.get(`/loan_status?cin_number=${cin}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response.data;
			});
}

export function getEligibilityProject() {
	return dispatch =>
		axiosAPI()
			.get('/eligiblityProductList')
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response.data;
			});
}

export function getCreditUserList(reqBody) {
	return dispatch =>
		axiosAPI()
			.post('/creditUserList', reqBody)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response.data;
			});
}

export const getDisbursementDetails = reqBody => {
	return dispatch =>
		axiosAPI()
			.post('/fetchDisbursement_details', reqBody)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response.data;
			});
};
export const getDisbursementComments = disbursementId => {
	return dispatch =>
		axiosAPI()
			.get(`/disbursement_comment_list?disbursement_id=${disbursementId}`)
			.then(res => {
				return res;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
};

export const getStampDutyFee = loanId => {
	return dispatch =>
		axiosAPI()
			.get(`/EMudra/stampDutyAmount?loan_id=${loanId}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
};

export const getUsersList = data => {
	const { loanId, userType } = data;
	return dispatch =>
		axiosAPI()
			// .get(`productDetails?white_label_id=${whiteLabelId}&product_id=${productId}`)
			.get(`/getUsersList/?loanId=${loanId}&userType=${userType}`)
			.then(res => {
				return res;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
};
export const getProductDetails = data => {
	const { whiteLabelId, productId } = data;
	return dispatch =>
		axiosAPI()
			.get(`productDetails?white_label_id=${whiteLabelId}&product_id=${productId}`)
			.then(res => {
				return res;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
};
export const getListOfProducts = data => {
	const { whiteLabelId, loanRequestType } = data;
	return dispatch =>
		axiosAPI()
			.get(`productDetails?white_label_id=${whiteLabelId}&loan_request_type=${loanRequestType}`)
			.then(res => {
				return res;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
};
export const getLoanTypes = loanProductId => {
	return dispatch =>
		axiosAPI()
			.get(`/loan_type?loan_product_id=${loanProductId}`)
			.then(res => {
				return res;
			})
			.catch(err => {
				// dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.message;
			});
};

// post method for DisbursementActionModal
export function updateDisbursementData(data) {
	const { apiEndPoint, reqBody } = data;
	return dispatch =>
		axiosAPI()
			.post(apiEndPoint, reqBody)
			.then(res => {
				return res;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}

// post method for DisbursementActionModal
export function addOrEditDisbursementDetails(data) {
	const { reqBody } = data;
	return dispatch =>
		axiosAPI()
			.post('/disbursement_details', reqBody)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				// console.log('err-', err);
				// console.log('err-', err && err.response);
				// console.log('err-', err && err.response && err.response.data && err.response.data);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function validateEmudraDisbursement(loanId) {
	return dispatch =>
		axiosAPI()
			.get(`/EMudra/validateDisbursement?loan_id=${loanId}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				// console.log('err-', err);
				// console.log('err-', err && err.response);
				// console.log('err-', err && err.response && err.response.data && err.response.data);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function verifyBTDisbursement(loanId) {
	return dispatch =>
		axiosCommonServicesAPI()
			.get(`/verifyBTDisbursement?loan_id=${loanId}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				// console.log('err-', err);
				// console.log('err-', err && err.response);
				// console.log('err-', err && err.response && err.response.data && err.response.data);
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function addVehicleDetails(payload) {
	return dispatch =>
		axiosAPI()
			.post('/vehicle_details', payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}
export function editCreditLimitDetails(payload) {
	return dispatch =>
		axiosAPI()
			.post('/credit_limit_update', payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}
export function getVehicletType(payload) {
	return dispatch =>
		axiosAPI()
			.get('/getVehicleType', { params: payload })
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function getLoanLimitData(payload) {
	const { loanId } = payload;
	return dispatch =>
		axiosAPI()
			.get(`/sanctionLimit/getByLoanProduct`, {
				params: {
					loanId: loanId,
				},
			})
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function getFinalApprovalStatus(payload) {
	return dispatch =>
		axiosAPI()
			.get('/sanctionLimit/getLoanApprovalStatus', { params: payload })
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function getApprovalUserList(payload) {
	return dispatch =>
		axiosAPI()
			.get('/sanctionLimit/getApprovalUserHierarchy', {
				params: payload,
			})
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function createApprovalRequest(payload) {
	return dispatch =>
		axiosAPI()
			.post('/sanctionLimit/createApprovalLog', payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function resetApproval(payload) {
	return dispatch =>
		axiosAPI()
			.put(
				`/sanctionLimit/updateFinalApprovalStatus?loanId=${payload.loanId}&&status=${payload.status}&&approvalLogId=${payload.approvalLogId}`
			)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function getEquipmentType(payload) {
	return dispatch =>
		axiosAPI()
			.get('/getEquipmentType', { params: payload })
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function getInsuranceFields(loanProductId) {
	return dispatch =>
		axiosAPI()
			.get(`/insurance/get-ins-config?product_id=${loanProductId}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function getSelectedInsuranceCompanies(loanId) {
	return dispatch =>
		axiosAPI()
			.get(`/insurance/fetch-ins-modes?loan_id=${loanId}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function submitInsuranceCompanies(payload) {
	return dispatch =>
		axiosAPI()
			.post('/insurance/select-ins-company', payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function getInsuranceApplicants(loanId) {
	return dispatch =>
		axiosAPI()
			.get(`/insurance/fetch-ins-sections?loan_id=${loanId}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function submitInsuranceApplicants(payload) {
	return dispatch =>
		axiosAPI()
			.post('/insure/applicant', payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function getPremiumCharges(payload) {
	return dispatch =>
		axiosAPI()
			.post('/insurance/calculate-premium', payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function submitPremiumCharges(payload) {
	return dispatch =>
		axiosAPI()
			.post('/insurance/submit-charges', payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function fetchInsuranceNomineeDetails(loanId) {
	return dispatch =>
		axiosAPI()
			.get(`/insurance/fetch-nominee-sections?loan_id=${loanId}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function submitInsuranceNomineeDetails(payload) {
	return dispatch =>
		axiosAPI()
			.post('/insurance/submit-nominees', payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function fetchPenetrationDetails(loanId) {
	return dispatch =>
		axiosAPI()
			.get(`/insurance/fetch-penetration-details?loan_id=${loanId}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function proceedWithSanction(payload) {
	return dispatch =>
		axiosAPI()
			.post('/insurance/submit-penetration', payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function getCadUsersList(payload) {
	return dispatch =>
		axiosAPI()
			.get('/getCadUsersList', {
				params: payload,
			})
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function reassignAndCreateApprovalUser(payload) {
	return dispatch =>
		axiosAPI()
			.put('/approvalLog/reassignAndCreateApprovalUser', payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function getBtDetails(loanId) {
	return dispatch =>
		axiosCommonServicesAPI()
			.get(`/getBtDetails?loan_id=${loanId}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function submitBtDetails(reqBody) {
	return dispatch =>
		axiosCommonServicesAPI()
			.post('/submitBTDetails', reqBody)
			.then(res => res.data)
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
export function submitPaymentLink(reqBody) {
	return dispatch =>
		axiosCommonServicesAPI()
			.post('/CashFree/initiate', reqBody)
			.then(res => res.data)
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function cancelPaymentLink(reqBody) {
	return dispatch =>
		axiosCommonServicesAPI()
			.post('/CashFree/cancel', reqBody)
			.then(res => res.data)
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function updateProgramType(reqBody) {
	return dispatch =>
		axiosCommonServicesAPI()
			.post('/updateProgramType', reqBody)
			.then(res => res.data)
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}
