import get from 'lodash/get';
import { decryptString } from '../../../utils/encrypt';

export const fakeAuth = {
	isAuthenticate() {
		let token = localStorage.getItem('token');
		return token !== null;
	},
	signOut() {
		localStorage.clear();
	},
	getToken() {
		let token = localStorage.getItem('token');
		return token;
	},
	newUser() {
		let user = localStorage.getItem('user');
		let newUser = user !== null && JSON.parse(decryptString(user)).newUser;
		return newUser === '0';
	},
	getPermission() {
		let permissions = localStorage.getItem('permission');
		let permission = permissions !== null && JSON.parse(decryptString(permissions));
		return permission;
	},
	getUser() {
		let user = localStorage.getItem('user');
		return JSON.parse(decryptString(user));
	},
	getWhiteLabel() {
		let whiteLabel = localStorage.getItem('whiteLabel');
		// console.log(JSON.parse(decryptString(whiteLabel)));
		return JSON.parse(decryptString(whiteLabel));
	},
};

export const getQueryStringValue = key => {
	return decodeURIComponent(
		window.location.search.replace(
			new RegExp('^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[.+*]/g, '\\$&') + '(?:\\=([^&]*))?).*$', 'i'),
			'$1'
		)
	);
};

export const getLogoutUrl = () => {
	let origin_url = localStorage.getItem('origin_url');
	const whiteLabel = fakeAuth.getWhiteLabel();

	if ((!origin_url && !whiteLabel) || window.location.origin.includes('localhost')) {
		// if configuration or session does not exist
		// or if it's localhost
		// redirect user to login page
		return window.location.origin;
	}
	let returnUrl = '';
	if (origin_url) {
		returnUrl = origin_url;
		const mandatoryFieldJson = get(whiteLabel, 'permission.white_label.mandatory_field', null);
		if (mandatoryFieldJson) {
			try {
				const parsedMandatoryField = JSON.parse(mandatoryFieldJson) || {};
				const adfsLogoutURL = get(parsedMandatoryField, 'adfs_login.adfs_logout_url', '') || '';
				const isAdfsLogin = get(parsedMandatoryField, 'adfs_login.only_adfs_login', false) || false;
				// console.log({ isAdfsLogin, adfsLogoutURL });
				if (isAdfsLogin && adfsLogoutURL && !(adfsLogoutURL.length === 0)) {
					returnUrl = adfsLogoutURL;
					return returnUrl;
				}
			} catch (error) {
				console.error('Error in parsing JSON: ', error);
			}
		}
	} else if (
		whiteLabel &&
		whiteLabel.permission &&
		whiteLabel.permission.white_label &&
		whiteLabel.permission.white_label.site_url
	) {
		returnUrl = whiteLabel.permission.white_label.site_url;
		const mandatoryFieldJson = get(whiteLabel, 'permission.white_label.mandatory_field', null);
		if (mandatoryFieldJson) {
			try {
				const parsedMandatoryField = JSON.parse(mandatoryFieldJson) || {};
				const adfsLogoutURL = get(parsedMandatoryField, 'adfs_login.adfs_logout_url', '') || '';
				const isAdfsLogin = get(parsedMandatoryField, 'adfs_login.only_adfs_login', false) || false;
				// console.log({ isAdfsLogin, adfsLogoutURL }, 'second one');

				if (isAdfsLogin && adfsLogoutURL && !(adfsLogoutURL.length === 0)) {
					returnUrl = adfsLogoutURL;
					return returnUrl;
				}
			} catch (error) {
				console.error('Error in parsing JSON: ', error);
			}
		}
	}

	try {
		if (returnUrl) {
			return `https://${returnUrl}/logout`;
		}
		return 'http://newapp.namastecredit.com';
	} catch (error) {
		console.error('error-getLogoutUrl-', error);
		return 'http://newapp.namastecredit.com';
	}
};
