import { combineReducers } from 'redux';
import { error } from './error';
import { user, whiteLabels } from './user';
import { userDetails } from './contacts';
import { loan } from './loan';
import { loanApproval } from '../loanApproval/reducer';
import { loanApprovalListing } from '../actions/loanListing/reducer';
import { sanctionApproval } from '../actions/SanctionApproval/reducer';

const MainReducer = combineReducers({
	error,
	user,
	whiteLabels,
	userDetails,
	loan,
	loanApproval,
	loanApprovalListing,
	sanctionApproval,
});

export default MainReducer;
