import { combineReducers } from 'redux';
import { createEntityReducer } from '../../../shared/utils/helper/reduxUtility';
import {
	GET_EXPOSURE_LIMIT,
	CREATE_TERMS_APPROVAL_LIST,
	GET_TERMS_APPROVAL_LIST,
	UPDATE_SANCTION_TERMS_STATUS,
	GET_TERMS_APPROVAL_USER_LIST,
	GET_TERMS_FINAL_APPROVAL_STATUS,
	TERMS_ADD_COMMENTS,
	UPDATE_TERMS_APPROVAL,
	GET_TERMS_LIST,
	APPROVE_SANCTION_TERMS_STATUS,
	SET_TNC_VALID_STATUS,
} from './types';

const exposurelimit = createEntityReducer(GET_EXPOSURE_LIMIT);
const createResponse = createEntityReducer(CREATE_TERMS_APPROVAL_LIST);
const approvalList = createEntityReducer(GET_TERMS_APPROVAL_LIST);
const updateResponse = createEntityReducer(UPDATE_TERMS_APPROVAL);
const approvalUserList = createEntityReducer(GET_TERMS_APPROVAL_USER_LIST);
const finalApprovalStatus = createEntityReducer(GET_TERMS_FINAL_APPROVAL_STATUS);
const addComments = createEntityReducer(TERMS_ADD_COMMENTS);
const updateSanctionTermStatus = createEntityReducer(UPDATE_SANCTION_TERMS_STATUS);
const approveSanctionTermStatus = createEntityReducer(APPROVE_SANCTION_TERMS_STATUS);
const termsList = createEntityReducer(GET_TERMS_LIST);
const tncValidStatus = createEntityReducer(SET_TNC_VALID_STATUS);

export const sanctionApproval = combineReducers({
	exposurelimit,
	createResponse,
	approvalList,
	updateResponse,
	approvalUserList,
	finalApprovalStatus,
	addComments,
	updateSanctionTermStatus,
	approveSanctionTermStatus,
	termsList,
	tncValidStatus,
});
