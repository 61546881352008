import React, { Component } from 'react';
import { Form, Icon, Input, Button, message } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { login, whiteLabel, getRelationshipManager } from '../../redux/actions';
import './index.scss';
import { Email, Password } from '../../shared/constants/signinImage';
import { getCustomUrl } from '../../App';

class Login extends Component {
	state = {
		isLoading: false,
		showErrorMessage: false,
		errorMessage: '',
	};

	onChange = e => {
		if (this.state.showErrorMessage === true) {
			this.setState({
				showErrorMessage: false,
			});
		}
		// console.log(e.target.value, 'e');
	};
	onLogin = e => {
		e.preventDefault();
		this.setState({
			isLoading: true,
		});
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.onLoginApi(values);
			} else {
				this.setState({
					isLoading: false,
				});
			}
		});
	};
	onLoginApi = values => {
		this.props.login(values).then(response => {
			const res = response || {};
			this.setState({
				isLoading: false,
			});

			if (!res || res.DES_CODE === 'NC03') {
				// message.error(res.DES_CODE === 'NC03' ? res.message : 'Something went wrong!');
				this.setState({
					showErrorMessage: true,
					errorMessage: res.message || 'Something went wrong!',
				});
				return;
			}

			if (res && res.status === 'nok') {
				// this.props.form.setFields({
				// 	emailid: {
				// 		value: values.emailid,
				// 		errors: [new Error('Invalid Credentials')],
				// 		// errors: [new Error(res.message)],
				// 	},
				// 	password: {
				// 		value: values.password,
				// 		errors: [new Error('Invalid Credentials')],
				// 		// errors: [new Error(res.message)],
				// 	},
				// });
				this.setState({
					showErrorMessage: true,
					errorMessage: res.message || 'Something went wrong!',
				});
			} else if (res && res.status === 'ok') {
				if (res && res.user && res.user.usertype && res.user.usertype === 'Sales') {
					localStorage.setItem('token', res.token);
					this.props.history.push(getCustomUrl() + '/sales/home');
				} else {
					localStorage.setItem('token', res.token);
					this.props.whiteLabel().then(res1 => {
						if (res1 && res1.status && res1.status === 'ok') {
							localStorage.setItem('bucketing_type', res1.permission.white_label.transaction_bucketing_type);
							this.props.history.push(getCustomUrl() + '/main/dashboard');

							const suburl =
								res.user && res.user.profile_completion !== 3 && res.user.corporateData
									? '/main/profile'
									: '/main/dashboard';
							this.props.history.push(getCustomUrl() + suburl);
						} else {
							message.error('something went wrong');
						}
					});
				}
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { isLoading } = this.state;

		return (
			<div className='login-wrapper'>
				<div>
					<Form onSubmit={this.onLogin} className='login-form'>
						<Form.Item>
							{getFieldDecorator('emailid', {
								rules: [
									{ required: true, message: 'Please input your email id!' },
									{
										type: 'email',
										message: 'The Input is not valid E-mail',
									},
								],
							})(
								<Input
									className='login-input'
									addonBefore={<Icon component={Email} />}
									placeholder='Email id'
									onChange={this.onChange}
								/>
							)}
						</Form.Item>
						<Form.Item>
							{getFieldDecorator('password', {
								rules: [{ required: true, message: 'Please input your Password!' }],
							})(
								<Input
									className='login-input'
									addonBefore={<Icon component={Password} />}
									type='password'
									placeholder='Password'
									onPressEnter={this.onLogin}
									onChange={this.onChange}
								/>
							)}
							{this.state.showErrorMessage && <div className='custom-error-message'>{this.state.errorMessage}</div>}
						</Form.Item>
						<Form.Item>
							<Button loading={isLoading} type='primary' onClick={this.onLogin} className='login-form-button'>
								<span className='login-form-button-text'>LOGIN</span>
							</Button>
						</Form.Item>
						<div onClick={() => this.props.history.push('/signup')} className='registered-user-button'>
							Not Regsitered Yet? - Register
						</div>
					</Form>
					<div className='login-form-footer'>
						<div className='login-form-footer-text-1'>Or</div>
						<div className='login-form-footer-text-2'>Sign in with your Social Network Account</div>
					</div>
				</div>
			</div>
		);
	}
}

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			login,
			whiteLabel,
			getRelationshipManager,
		},
		dispatch
	);
}

export default Form.create()(withRouter(connect(null, matchDispatchToProps)(Login)));
