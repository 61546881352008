export const GET_EXPOSURE_LIMIT = 'GET_EXPOSURE_LIMIT';
export const CREATE_TERMS_APPROVAL_LIST = 'CREATE_TERMS_APPROVAL_LIST';
export const GET_TERMS_APPROVAL_LIST = 'GET_TERMS_APPROVAL_LIST';
export const UPDATE_TERMS_APPROVAL = 'UPDATE_TERMS_APPROVAL';
export const GET_TERMS_APPROVAL_USER_LIST = 'GET_TERMS_APPROVAL_USER_LIST';
export const GET_TERMS_FINAL_APPROVAL_STATUS = 'GET_TERMS_FINAL_APPROVAL_STATUS';
export const TERMS_ADD_COMMENTS = 'TERMS_ADD_COMMENTS';
export const UPDATE_SANCTION_TERMS_STATUS = 'UPDATE_SANCTION_TERMS_STATUS';
export const APPROVE_SANCTION_TERMS_STATUS = 'APPROVE_SANCTION_TERMS_STATUS';
export const GET_TERMS_LIST = 'GET_TERMS_LIST';
export const SET_TNC_VALID_STATUS = 'SET_TNC_VALID_STATUS';
