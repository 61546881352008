import React, { PureComponent } from 'react';
import { Button } from 'antd';
import { getWhiteLabel, buttonTextColor } from '../../utils/helper/getWhitelabel';

export default class CustomButton extends PureComponent {
	render() {
		const { shape, size, type, loading, onClick, className, disabled, children, color } = this.props;
		const style = this.props.style || {};
		const buttonColor = getWhiteLabel();
		const textolor = buttonTextColor();
		const customStyle = {
			color: `${textolor}`,
			//color: '#1e1ebd',
			fontSize: '14px',
			border: 'none',
			...style,
		};
		if (color) {
			customStyle.background = color === 'blue' ? '#2a2add' : '#1e1ebd';
			// customStyle.background = 'rgb(8, 32, 128)';
		} else {
			customStyle.backgroundImage = `linear-gradient(to bottom, ${buttonColor})`;
		}
		return (
			<Button
				shape={shape}
				size={size}
				type={type && type}
				loading={loading && loading}
				onClick={onClick && onClick}
				className={className}
				disabled={disabled}
				style={customStyle}
			>
				{children}
			</Button>
		);
	}
}
