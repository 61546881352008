import axios from 'axios';

import { getUrl } from '../../shared/utils/helper/baseUrls';
import { USER, WHITELABEL, ERROR } from '../actionTypes';
import { axiosAPI } from '../../shared/utils/helper/baseUrls';
import { message } from 'antd';
import { decryptRes, encryptReq, encryptString } from '../../utils/encrypt';
import { fakeAuth } from '../../shared/utils/helper/RouteHelper';

export function login(loginObj) {
	let white_label_id = null;
	const wt_lbl_id = JSON.parse(localStorage.getItem('wt_lbl'));
	if (wt_lbl_id && wt_lbl_id.id) {
		white_label_id = wt_lbl_id.id;
	}
	return dispatch =>
		axios
			.post(`${getUrl()}/login`, {
				email: loginObj.emailid,
				password: loginObj.password,
				white_label_id,
			})
			.then(res => {
				if (res.data && res.data.status === 'ok') {
					const userDetails = res.data.user && res.data.user;
					let user = {
						...userDetails,
						userid: userDetails && userDetails.id,
						email: userDetails && userDetails.email,
						name: userDetails && userDetails.name,
						usertype: userDetails && userDetails.usertype,
						newUser: userDetails && userDetails.firstlogin,
						profile_completion: userDetails && userDetails.profile_completion,
						white_label_id: userDetails && userDetails.white_label_id,
						assigned_sales_user: userDetails && userDetails.assigned_sales_user,
						profilePic: userDetails && userDetails.pic,
						city: userDetails && userDetails.city,
						contact: userDetails && userDetails.contact,
						corporateData: userDetails && userDetails.corporateData,
						user_sub_type: userDetails && userDetails.user_sub_type,
						corporateCriteriaData: userDetails && userDetails.corporateCriteriaData,
						is_corporate: userDetails && userDetails.is_corporate,
						is_other: userDetails && userDetails.is_other,
						white_label: res.data && res.data.whiteLabel && res.data.whiteLabel,
					};
					localStorage.setItem('user', encryptString(JSON.stringify(user)));
					localStorage.setItem('wt_lbl', JSON.stringify(res.data.whiteLabel && res.data.whiteLabel));
					dispatch({ type: USER, payload: res.data.user });
				}
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err && err.response && err.response.data;
			});
}

export function whiteLabel() {
	let white_label_id = fakeAuth.getUser().white_label.id || '';
	let token = localStorage.getItem('token');
	return dispatch =>
		axios
			.get(`${getUrl()}/WhiteLabelSolution/${white_label_id}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then(response => {
				const res = response.data;
				//console.log('response', res);
				res.permission = decryptRes(res.permission);
				//console.log('response', res);
				const whiteLabel = {
					...res,
					logo:
						res.permission &&
						res.permission.white_label &&
						res.permission.white_label.logo &&
						res.permission.white_label.logo,
					...res.permission.white_label.color_theme_react,
				};
				const permission = res.permission && res.permission.permission && res.permission.permission;
				localStorage.setItem('whiteLabel', encryptString(JSON.stringify(whiteLabel)));
				localStorage.setItem('permission', encryptString(JSON.stringify(permission)));
				dispatch({ type: WHITELABEL, payload: whiteLabel });
				return res;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function getCityState(pincode) {
	let country = JSON.parse(localStorage.getItem('wt_lbl')).country;
	function getUrl() {
		if (country === 'US') {
			return `/pincode?code=${pincode}&country=US`;
		} else if (country === 'UK') {
			return `/pincode?code=${pincode}&country=UK`;
		} else {
			return `/pincode?code=${pincode}`;
		}
	}
	return dispatch =>
		axiosAPI()
			.get(getUrl())
			.then(response => {
				if (country === 'UK') {
					return response.data;
				} else {
					let cityState = {
						district: [],
						state: [],
						locality: [],
					};
					const res = response.data;
					res.district &&
						res.district.forEach(item => {
							cityState.district.push({ value: item, id: item });
						});
					res.state &&
						res.state.forEach(item => {
							cityState.state.push({ value: item, id: item });
						});
					res.locality &&
						res.locality.forEach(item => {
							cityState.locality.push({ value: item, id: item });
						});
					return cityState;
				}
			})
			.catch(err => {
				message.error(
					err.response ? (err.response.data.message ? err.response.data.message : err.response) : 'no network'
				);
				console.error('err.response.data.message', err.response, err.response.data.message);
				// dispatch({
				// 	type: ERROR,
				// 	payload: err.response
				// 		? err.response.data.message
				// 			? err.response.data.message
				// 			: err.response
				// 		: 'no network',
				// });
			});
}

export function CPType() {
	return dispatch =>
		axios
			.post(`${getUrl()}/channelType`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function checkUserExists() {
	return dispatch =>
		axiosAPI()
			.get(`/check`)
			.then(res => {
				const userDetails = res.data;
				let user = {
					...userDetails,
					userid: userDetails && userDetails.id,
					email: userDetails && userDetails.email,
					name: userDetails && userDetails.name,
					usertype: userDetails && userDetails.usertype,
					newUser: userDetails && userDetails.firstlogin,
					profile_completion: userDetails && userDetails.profile_completion,
					white_label_id: userDetails && userDetails.white_label_id,
					assigned_sales_user: userDetails && userDetails.assigned_sales_user,
					profilePic: userDetails && userDetails.pic,
					city: userDetails && userDetails.city,
					contact: userDetails && userDetails.contact,
					corporateData: userDetails && userDetails.corporateData,
					user_sub_type: userDetails && userDetails.user_sub_type,
					corporateCriteriaData: userDetails && userDetails.corporateCriteriaData,
					is_corporate: userDetails && userDetails.is_corporate,
					white_label: userDetails && userDetails.whiteLabel && userDetails.whiteLabel,
				};
				localStorage.setItem('user', encryptString(JSON.stringify(user)));
				localStorage.setItem('wt_lbl', JSON.stringify(userDetails.whiteLabel && userDetails.whiteLabel));
				dispatch({ type: USER, payload: res && res });
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}

export function changePassword(passwordObj) {
	return dispatch =>
		axiosAPI()
			.post(`/resetPassword`, passwordObj)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}

export function getRelationshipManager(assigned_sales_user) {
	return dispatch =>
		axiosAPI()
			.get(`/users?id=${encryptReq(assigned_sales_user)}`)
			.then(res => {
				// console.log('getRelationshipManager-users-res-', { res });
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
			});
}

export function addSanction(addSanctionValues) {
	// console.log('addSanction-reqbody-', addSanctionValues);
	// return;
	return dispatch =>
		axiosAPI()
			.post(`/addSanction`, addSanctionValues)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}

export function addSanctionAdditionalData(payload) {
	return dispatch =>
		axiosAPI()
			.post(`/addSanctionAdditional`, payload)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}

export function createUser(userObj) {
	return dispatch =>
		axiosAPI()
			.post(`/user_creation`, userObj)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}

export function trigerOtpSend(userObj) {
	return dispatch =>
		axiosAPI()
			.post(`/sendOtp`, userObj)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}

export function verifyOtp(userObj) {
	return dispatch =>
		axiosAPI()
			.post(`/verifyOtp`, userObj)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}

export function getCity(pin) {
	return dispatch =>
		axios
			.get(` http://api.namastecredit.com/welcome/getCityStateByPincode?keyword=${pin}`)
			.then(res => {
				return res.data;
			})
			.catch(err => {
				dispatch({ type: ERROR, payload: err.response ? err.response : 'no network' });
				return err.response;
			});
}
