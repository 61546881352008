import { combineReducers } from 'redux';
import { createEntityReducer } from '../../../shared/utils/helper/reduxUtility';
import {
	GET_LOAN_APPROVAL_LIST_STATUS,
	GET_LOAN_DETAILS,
	GET_RE_ASSIGN_USER_LIST,
	CREATE_RE_ASSIGN_USER,
	LOAN_SEARCH,
} from './types';

const getLoanStatus = createEntityReducer(GET_LOAN_APPROVAL_LIST_STATUS);
const getLoanDetails = createEntityReducer(GET_LOAN_DETAILS);
const reAssignUserList = createEntityReducer(GET_RE_ASSIGN_USER_LIST);
const createReAssignUser = createEntityReducer(CREATE_RE_ASSIGN_USER);
const loanSearch = createEntityReducer(LOAN_SEARCH);

export const loanApprovalListing = combineReducers({
	getLoanStatus,
	getLoanDetails,
	reAssignUserList,
	createReAssignUser,
	loanSearch,
});
