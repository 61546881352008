import React, { Component } from 'react';
import Lottie from 'lottie-react-web';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';

import './index.scss';
import images from '../../shared/utils/helper/imageurls';
import Login from '../Login';
import NCLoader from '../../shared/components/NCLoader';
import FAQ from '../../shared/components/FAQ';
import Signin from '../Signin';
import NCLogo from '../../shared/constants/NCLogo.json';
import { checkUserExists, whiteLabel } from '../../redux/actions';
import { axiosAPI } from '../../shared/utils/helper/baseUrls';
import { getCustomUrl } from './../../App';
import { decryptBase64, decryptRes, decryptSessionId } from '../../utils/encrypt';

class LandingPage extends Component {
	state = {
		gettingToken: false,
		validatingURL: true,
	};
	// sample redirection urls
	// https://nctestnew.namastecredit.com/newui?ZzdkYUprUXNOMUd3Mm1HeTJ5Q1pvQT09=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjo4ODk1NywibmFtZSI6IlRlc3QgQmFuayBBZG1pbiIsImVtYWlsIjoidGVzdGJhbmthZG1pbkBnbWFpbC5jb20iLCJjb250YWN0IjoiOTAwMDAwMDAwMCIsImNhY29tcG5hbWUiOiIiLCJjYXBhbmNhcmQiOm51bGwsImFkZHJlc3MxIjpudWxsLCJhZGRyZXNzMiI6bnVsbCwicGluY29kZSI6bnVsbCwibG9jYWxpdHkiOm51bGwsImNpdHkiOiJBaG1lZGFiYWQiLCJzdGF0ZSI6IkdVSkFSQVQiLCJ1c2VydHlwZSI6IkJhbmsiLCJsZW5kZXJfaWQiOjEyNTE5LCJwYXJlbnRfaWQiOjAsInVzZXJf
	// https://nctestnew.namastecredit.com/newui?ZzdkYUprUXNOMUd3Mm1HeTJ5Q1pvQT09=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjo4ODk1NywibmFtZSI6IlRlc3QgQmFuayBBZG1pbiIsImVtYWlsIjoidGVzdGJhbmthZG1pbkBnbWFpbC5jb20iLCJjb250YWN0IjoiOTAwMDAwMDAwMCIsImNhY29tcG5hbWUiOiIiLCJjYXBhbmNhcmQiOm51bGwsImFkZHJlc3MxIjpudWxsLCJhZGRyZXNzMiI6bnVsbCwicGluY29kZSI6bnVsbCwibG9jYWxpdHkiOm51bGwsImNpdHkiOiJBaG1lZGFiYWQiLCJzdGF0ZSI6IkdVSkFSQVQiLCJ1c2VydHlwZSI6IkJhbmsiLCJsZW5kZXJfaWQiOjEyNTE5LCJwYXJlbnRfaWQiOjAsInVzZXJfZ3JvdXBfaWQiOjMsImFzc2lnbmVkX3NhbGVzX3VzZXIiOm51bGwsIm9yaWdpbmF0b3IiOjI4NDAsImlzX2xlbmRlcl9hZG1pbiI6MSwic3RhdHVzIjoiYWN0aXZlIiwib3N2X25hbWUiOm51bGwsImZpcnN0bG9naW4iOiIwIiwiY3JlYXRlZG9uIjoiMjAyMS0wOC0wMlQxMTo1NTozOC4wMDBaIiwidXBkYXRlX3RpbWUiOiIyMDIyLTAzLTIxVDA2OjQzOjEwLjAwMFoiLCJpc19sZW5kZXJfbWFuYWdlciI6MCwib3JpZ2luIjoiU3VwZXIgQWRtaW5pc3RyYXRvciBBZGQiLCJ3aGl0ZV9sYWJlbF9pZCI6IjI4IiwiZGVhY3RpdmF0ZV9yZWFzc2lnbiI6Ik5vIiwibm90aWZpY2F0aW9uX3B1cnBvc2UiOjQsInVzZXJfc3ViX3R5cGUiOiJDcmVkaXQiLCJub3RpZmljYXRpb25fZmxhZyI6Im5vIiwiY3JlYXRlZGJ5VXNlciI6Mjg0MCwic291cmNlIjoiTkNUZXN0TmV3IiwiY2hhbm5lbF90eXBlIjoiMCIsIm90cCI6bnVsbCwid29ya190eXBlIjpudWxsLCJwcm9maWxlX2NvbXBsZXRpb24iOjAsInBpYyI6bnVsbCwibG9naW5fc3RhdHVzIjoxNjQ3ODQ2MTQ1NzE3NDY0OCwiYnJhbmNoX2lkIjpudWxsLCJpc19jb3Jwb3JhdGUiOm51bGx9LCJpYXQiOjE2NDc4NDYxNDUsImV4cCI6MTY0NzkzMjU0NX0.bKxiWYUaD-MAhBzd1dtovojfjoXvjijhvxgQPUYHQ9c&label_id=Mjg=
	// https://nctestnew.namastecredit.com/newui/main/loanlist/loanapplications/create/P1p6ZGtZVXByVVhOT01VZDNNbTFIZVRKNVExcHZRVDA5PWV5SmhiR2NpT2lKSVV6STFOaUlzSW5SNWNDSTZJa3BYVkNKOS5leUoxYzJWeUlqcDdJbWxrSWpvNE9EazFOeXdpYm1GdFpTSTZJbFJsYzNRZ1FtRnVheUJCWkcxcGJpSXNJbVZ0WVdsc0lqb2lkR1Z6ZEdKaGJtdGhaRzFwYmtCbmJXRnBiQzVqYjIwaUxDSmpiMjUwWVdOMElqb2lPVEF3TURBd01EQXdNQ0lzSW1OaFkyOXRjRzVoYldVaU9pSWlMQ0pqWVhCaGJtTmhjbVFpT201MWJHd3NJbUZrWkhKbGMzTXhJanB1ZFd4c0xDSmhaR1J5WlhOek1pSTZiblZzYkN3aWNHbHVZMjlrWlNJNmJuVnNiQ3dpYkc5allXeHBkSGtpT201MWJHd3NJbU5wZEhraU9pSkJhRzFsWkdGaVlXUWlMQ0p6ZEdGMFpTSTZJa2RWU2tGU1FWUWlMQ0oxYzJWeWRIbHdaU0k2SWtKaGJtc2lMQ0pzWlc1a1pYSmZhV1FpT2pFeU5URTVMQ0p3WVhKbGJuUmZhV1FpT2pBc0luVnpaWEpmWjNKdmRYQmZhV1FpT2pNc0ltRnpjMmxuYm1Wa1gzTmhiR1Z6WDNWelpYSWlPbTUxYkd3c0ltOXlhV2RwYm1GMGIzSWlPakk0TkRBc0ltbHpYMnhsYm1SbGNsOWhaRzFwYmlJNk1Td2ljM1JoZEhWeklqb2lZV04wYVhabElpd2liM04yWDI1aGJXVWlPbTUxYkd3c0ltWnBjbk4wYkc5bmFXNGlPaUl3SWl3aVkzSmxZWFJsWkc5dUlqb2lNakF5TVMwd09DMHdNbFF4TVRvMU5Ub3pPQzR3TURCYUlpd2lkWEJrWVhSbFgzUnBiV1VpT2lJeU1ESXlMVEF6TFRJeFZEQTJPalF6T2pFd0xqQXdNRm9pTENKcGMxOXNaVzVrWlhKZmJXRnVZV2RsY2lJNk1Dd2liM0pwWjJsdUlqb2lVM1Z3WlhJZ1FXUnRhVzVwYzNSeVlYUnZjaUJCWkdRaUxDSjNhR2wwWlY5c1lXSmxiRjlwWkNJNklqSTRJaXdpWkdWaFkzUnBkbUYwWlY5eVpXRnpjMmxuYmlJNklrNXZJaXdpYm05MGFXWnBZMkYwYVc5dVgzQjFjbkJ2YzJVaU9qUXNJblZ6WlhKZmMzVmlYM1I1Y0dVaU9pSkRjbVZrYVhRaUxDSnViM1JwWm1sallYUnBiMjVmWm14aFp5STZJbTV2SWl3aVkzSmxZWFJsWkdKNVZYTmxjaUk2TWpnME1Dd2ljMjkxY21ObElqb2lUa05VWlhOMFRtVjNJaXdpWTJoaGJtNWxiRjkwZVhCbElqb2lNQ0lzSW05MGNDSTZiblZzYkN3aWQyOXlhMTkwZVhCbElqcHVkV3hzTENKd2NtOW1hV3hsWDJOdmJYQnNaWFJwYjI0aU9qQXNJbkJwWXlJNmJuVnNiQ3dpYkc5bmFXNWZjM1JoZEhWeklqb3hOalEzT0RRMk1UUTFOekUzTkRZME9Dd2lZbkpoYm1Ob1gybGtJanB1ZFd4c0xDSnBjMTlqYjNKd2IzSmhkR1VpT201MWJHeDlMQ0pwWVhRaU9qRTJORGM0TkRZeE5EVXNJbVY0Y0NJNk1UWTBOemt6TWpVME5YMC5iS3hpV1lVYUQtTUFoQnpkMWR0b3ZvamZqb1h2amlqaHZ4Z1FQVVlIUTljJmxhYmVsX2lkPU1qZz0=/MQ==/MQ==
	// https://nctestnew.namastecredit.com/newui?ZzdkYUprUXNOMUd3Mm1HeTJ5Q1pvQT09=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjo4ODk1NywibmFtZSI6IlRlc3QgQmFuayBBZG1pbiIsImVtYWlsIjoidGVzdGJhbmthZG1pbkBnbWFpbC5jb20iLCJjb250YWN0IjoiOTAwMDAwMDAwMCIsImNhY29tcG5hbWUiOiIiLCJjYXBhbmNhcmQiOm51bGwsImFkZHJlc3MxIjpudWxsLCJhZGRyZXNzMiI6bnVsbCwicGluY29kZSI6bnVsbCwibG9jYWxpdHkiOm51bGwsImNpdHkiOiJBaG1lZGFiYWQiLCJzdGF0ZSI6IkdVSkFSQVQiLCJ1c2VydHlwZSI6IkJhbmsiLCJsZW5kZXJfaWQiOjEyNTE5LCJwYXJlbnRfaWQiOjAsInVzZXJfZ3JvdXBfaWQiOjMsImFzc2lnbmVkX3NhbGVzX3VzZXIiOm51bGwsIm9yaWdpbmF0b3IiOjI4NDAsImlzX2xlbmRlcl9hZG1pbiI6MSwic3RhdHVzIjoiYWN0aXZlIiwib3N2X25hbWUiOm51bGwsImZpcnN0bG9naW4iOiIwIiwiY3JlYXRlZG9uIjoiMjAyMS0wOC0wMlQxMTo1NTozOC4wMDBaIiwidXBkYXRlX3RpbWUiOiIyMDIyLTAzLTIxVDA3OjQwOjE4LjAwMFoiLCJpc19sZW5kZXJfbWFuYWdlciI6MCwib3JpZ2luIjoiU3VwZXIgQWRtaW5pc3RyYXRvciBBZGQiLCJ3aGl0ZV9sYWJlbF9pZCI6IjI4IiwiZGVhY3RpdmF0ZV9yZWFzc2lnbiI6Ik5vIiwibm90aWZpY2F0aW9uX3B1cnBvc2UiOjQsInVzZXJfc3ViX3R5cGUiOiJDcmVkaXQiLCJub3RpZmljYXRpb25fZmxhZyI6Im5vIiwiY3JlYXRlZGJ5VXNlciI6Mjg0MCwic291cmNlIjoiTkNUZXN0TmV3IiwiY2hhbm5lbF90eXBlIjoiMCIsIm90cCI6bnVsbCwid29ya190eXBlIjpudWxsLCJwcm9maWxlX2NvbXBsZXRpb24iOjAsInBpYyI6bnVsbCwibG9naW5fc3RhdHVzIjoxNjQ3ODQ4NTQ0OTk2NDQ4MCwiYnJhbmNoX2lkIjpudWxsLCJpc19jb3Jwb3JhdGUiOm51bGx9LCJpYXQiOjE2NDc4NDg1NDUsImV4cCI6MTY0NzkzNDk0NX0.FM6K2COtxk_9UB64puY-U2Hb1ar2hlNoHa8uU-96DT8&label_id=Mjg=&ncbiz=
	componentDidMount() {
		const searchParams = window.location.search || '';
		let token = this.getQueryStringValue({ params: searchParams, key: 'ZzdkYUprUXNOMUd3Mm1HeTJ5Q1pvQT09' });
		token = decryptSessionId(token);
		token = token ? decryptBase64(token) : '';
		token = token ? decryptBase64(token) : '';
		token = token.slice(0, -10);

		let productId = this.getQueryStringValue({ params: searchParams, key: 'ncbiz' }) || '';
		let urlParamsObj = new URLSearchParams(searchParams);
		if (urlParamsObj.get('change_password') === 'yes') {
			localStorage.setItem('pwd_chng', 'yes');
		}
		if (token === '') {
			if (window.location.origin.includes('localhost')) {
				this.setState({ gettingToken: true, validatingURL: false });
			} else {
				window.open('/login', '_self');
			}
		} else {
			localStorage.setItem('token', token);
			this.setWhiteLabelId(window.location.href);
			this.props.checkUserExists().then(res => {
				if (res && res.status === 401) {
					this.props.history.push('');
					this.setState({ gettingToken: true });
				} else {
					if (res === undefined) {
						message.error('Something went wrong!');
					} else {
						if (res.usertype && res.usertype === 'Sales') {
							this.props.history.push(getCustomUrl() + '/sales/home');
						} else {
							this.props.whiteLabel().then(res => {
								if (res) {
									if (productId === '') {
										this.goToDashboard();
									} else {
										this.goToLoanApplication(productId);
									}
								} else {
									message.error('Something went wrong!');
								}
							});
						}
					}
				}
			});
		}
	}
	goToLoanApplication = productId => {
		const type = 'create';
		this.props.history.push(
			getCustomUrl() + `/main/loanlist/loanapplications/${type}/${btoa(productId)}/${btoa(1)}/${btoa(1)}`
		);
	};
	getQueryStringValue = ({ params, key }) => {
		return decodeURIComponent(
			params.replace(
				new RegExp('^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[.+*]/g, '\\$&') + '(?:\\=([^&]*))?).*$', 'i'),
				'$1'
			)
		);
	};
	goToDashboard = () => {
		this.props.history.push(getCustomUrl() + '/main/dashboard');
	};

	setWhiteLabelId = async urlHref => {
		try {
			let b = urlHref.split('&');
			let c = b[b.length - 1];
			let idEN = c.split('label_id=');
			let id = atob(String(idEN[idEN.length - 1]));

			let data = await axiosAPI().get(`whitelabelsolution/${id}`);
			if (data.data.status === 'ok') {
				data.data.permission = decryptRes(data.data.permission);
				data.data.permission.white_label &&
					data.data.permission.white_label.site_url &&
					localStorage.setItem('origin_url', data.data.permission.white_label.site_url);
			}
		} catch (error) {
			console.error('Silte url ...', error);
		}
	};

	render() {
		const { gettingToken, validatingURL } = this.state;
		if (validatingURL) {
			return <NCLoader />;
		}
		if (!gettingToken)
			return (
				<Lottie
					options={{
						animationData: NCLogo,
					}}
					width={500}
				/>
			);
		else {
			return (
				<div>
					<div className='landing-page-wrapper'>
						<div className='landing-page-first-section'>
							<div className='landing-page-logo'>
								<img src={images['NCLogo']} alt='namaste-credit-logo' />
							</div>
							<div className='landing-page-text'>We are now connected to 50 Banks!</div>
						</div>
						<div className='landing-page-second-section'>
							<div className='landing-page-info'>
								<div className='landing-page-info-text-1'>
									Getting business loans just got easier. Across 25 locations borrowers have a network of Loan partners
									and Loan Specialists who can connect to 2500+ cities and towns pan India
								</div>
								<div className='landingpage-faq'>
									<FAQ />
								</div>
							</div>
							<div className='landing-page-login'>
								{this.props.location.pathname === '/signup' ? <Signin /> : <Login />}
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			checkUserExists,
			whiteLabel,
		},
		dispatch
	);
}

export default withRouter(connect(null, matchDispatchToProps)(LandingPage));
