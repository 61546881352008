import taggedTemplate from '../utils/taggedTemplate';

const API_END_POINT = process.env.REACT_APP_API_URL;
const ENDPOINT_BANK = process.env.REACT_APP_BANK_API;
const OTP_API_END_POINT = process.env.REACT_APP_OTP_URL;
const APP_DOMAIN = process.env.REACT_APP_DOMAIN;
const COMMON_SERVICES_API_END_POINT = process.env.REACT_APP_COMMON_API_URL;
const CUSTOMER_FETCH_API_END_POINT = process.env.REACT_APP_CUSTOMER_FETCH_API_URL;
const BIOMETRIC_REDIRECT = process.env.REACT_APP_AADHAR_REDIRECT_URL;

const LOAN_ORIGIN_NC_ONBOARDING = process.env.REACT_APP_LOAN_ORIGIN_NCONBOARDING;
const LOAN_ORIGIN_UIUX = process.env.REACT_APP_LOAN_ORIGIN_NCONBOARDING;

const CLIENT_VERIFY_URL = `${ENDPOINT_BANK}/sails-exp/ClientVerify`;

const BANK_TOKEN_API = `${ENDPOINT_BANK}/generateLink`;
const CUB_ACCOUNT_MINI_STATEMENT = `${ENDPOINT_BANK}/cub/accountMiniStatement`;

const UPLOAD_CUB_STATEMENT = `${ENDPOINT_BANK}/uploadToSailsExp`;
const BANK_LIST_API = `${ENDPOINT_BANK}/bank_list`;
const FETCH_CIBIL_SCORE = `${ENDPOINT_BANK}/equifax/fetchData`;

const WHITE_LABEL_URL = taggedTemplate`${API_END_POINT}/wot/whitelabelsolution?name=${'name'}`;
const PRODUCT_LIST_URL = taggedTemplate`${API_END_POINT}/productDetails?white_label_id=${'whiteLabelId'}`;
const PRODUCT_DETAILS_URL = taggedTemplate`${API_END_POINT}/productDetails?white_label_id=${'whiteLabelId'}&product_id=${'productId'}`;
const DOCS_UPLOAD_URL = taggedTemplate`${API_END_POINT}/loanDocumentUpload?userId=${'userId'}`;
const DOCS_UPLOAD_URL_LOAN = taggedTemplate`${API_END_POINT}/loanDocumentUpload?userid=10626`;

const BORROWER_UPLOAD_URL = `${API_END_POINT}/borrowerdoc-upload`;

const GENERATE_OTP_URL = `${API_END_POINT}/cub/generateOtp`;

const GET_BANK_DETAILS = `${API_END_POINT}/bank_details`;
const TRIGGER_PENNY_DROP = `${COMMON_SERVICES_API_END_POINT}/pennydrop/verification/trigger`;
const PENNY_DROP_STATUS_FETCH = `${COMMON_SERVICES_API_END_POINT}/pennydrop/verification/status`;

const VERIFY_OTP_URL = `${API_END_POINT}/cub/verifyOtp`;

const CREATE_CASE = `${API_END_POINT}/cub/createCase`;

const CREATE_CASE_OTHER_USER = `${API_END_POINT}/addDirector`;

const SEARCH_BANK_BRANCH_LIST = taggedTemplate`${API_END_POINT}/getBranchList?bankId=${'bankId'}
`;

const SEARCH_LOAN_ASSET = `${API_END_POINT}/searchByBrandname`;

const UPDATE_LOAN_ASSETS = `${API_END_POINT}/insertLoanAssets/`;

const SECRET = process.env.REACT_APP_SECRET || '';
const PINCODE_ADRRESS_FETCH = taggedTemplate`${API_END_POINT}/pincode?code=${'pinCode'}`;

const ROC_DATA_FETCH = `${ENDPOINT_BANK}/ROCData`;

const BUSSINESS_LOAN_CASE_CREATION = `${API_END_POINT}/casecreation_uiux`;
const BUSSINESS_LOAN_CASE_CREATION_EDIT = `${API_END_POINT}/loan/edit/`;

const LOGIN_CREATEUSER = `${API_END_POINT}/login/createUser`;

const WHITELABEL_ENCRYPTION_API = `${API_END_POINT}/case-whitelabelEncrypt`;

const SEARCH_COMPANY_NAME = `${API_END_POINT}/companySearch`;

const ADD_SUBSIDIARY_DETAILS = `${API_END_POINT}/addSubsidiaryDetails`;
const ADD_BANK_DETAILS = `${API_END_POINT}/addBankDetailsNew`; // addBankDetailsUiux

const ADD_SHAREHOLDER_DETAILS = `${API_END_POINT}/businessShareholder/create`;
const ADD_REFENCE_DETAILS = `${API_END_POINT}/LoanReferences/create`;

const BANK_LIST_FETCH = `${API_END_POINT}/BankMaster`;

const DOCTYPES_FETCH = `${API_END_POINT}/loan/documentTypes/`;
const TEMPLATE_FETCH = `${API_END_POINT}/populateTemplate/excel`;
const CIN_UPDATE = `${API_END_POINT}/cin-update`;

const APP_CLIENT = window.location.hostname === 'localhost' ? 'clix.loan2pal.com' : window.location.hostname;
const CLIENT_EMAIL_ID = 'clix@nc.com';
const REDIRECT_CREATE = window.location.hostname === 'localhost' ? '/' : '/onboarding/applyloan';

const DELETE_DOCUMENT = `${API_END_POINT}/documentDelete`;
const VIEW_DOCUMENT = `${API_END_POINT}/viewDocument`;

const FETCH_LMS_DISB_STATUS = `${API_END_POINT}/finnone/fetchDisbursementStatus`;
const RETRIGGER_LMS_DISBURSEMENT = `${API_END_POINT}/finnone/triggerDisbursement`;
const DIRECTOR_STATUS = `${API_END_POINT}/get_director_status`;
const DELETE_SANCTION_ASSEST_DATA = `${API_END_POINT}/delete_asset`;
const DELETE_CREDIT_LIMIT_DATA = `${API_END_POINT}/credit_limit_update`;

const DELETE_SANCTION_ADDITIONAL_ASSEST_DATA = `${API_END_POINT}/delete_sanction_additional_data`;

const NC_STATUS_CODE = {
	OK: 'ok',
	NC200: 'NC200',
	NC202: 'NC202',
	NC302: 'NC302',
	NC303: 'NC303',
	NC305: 'NC305',
	NC306: 'NC306 ',
	NC308: 'NC308 ',
	NC500: 'NC500',
};

const USER_ROLES = {
	User: 'user',
	'Co-applicant': 'Co-applicant',
	Guarantor: 'Guarantor',
};

const USER_TYPE = {
	maker: 'Macker',
	checker: 'Checker',
	bpo: 'BPO',
};

const ICON_PERMISSIONS = {
	raise_query_to_branch_icon: 'raise_query_to_branch_icon',
	move_to_credit_review_icon: 'move_to_credit_review_icon',
	move_to_credit_assigned_icon: 'move_to_credit_assigned_icon',
};

// json for icon-names
const ICON_NAMES = {
	view: 'view',
	comments: 'comments',
	upload: 'upload',
	edit: 'edit',
	on_site_verification: 'on_site_verification',
	verification: 'verification',
	initiate: 'initiate',
	not_qualify: 'not_qualify',
	external_evaluation: 'external_evaluation',
	document_status: 'document_status',
	raise_query_to_branch: 'raise_query_to_branch',
	move_to_credit_review: 'move_to_credit_review',
	move_to_credit_assigned: 'move_to_credit_assigned',
	restore: 'restore',
	download: 'download',
	reject: 'reject',
	assign_to_tl_icon: 'assign_to_tl_icon',
	assign_to_analyst_icon: 'assign_to_analyst_icon',
	generate_word_icon: 'generate_word_icon',
	generate_pdf_icon: 'generate_pdf_icon',
	copy_to_clipboard: 'copy_to_clipboard',
	sanction_terms_conditions_icon: 'sanction_terms_conditions_icon',
	e_registrations: 'e_registrations',
	integrations: 'integrations',
	risk_profiling: 'risk_profiling',
	cam_profiling: 'cam_profiling',
	insurance_finalization: 'insurance_finalization',
	additional_verification: 'additional_verification',
};

export const APPLICATION_STATUS_UNIQUE_ID = {
	APPLICATION: '[1][null][null][null][null]',
	IN_PROGRESS: '[2][8][null][null][null]',
	CREDIT_ASSIGNED: '[2][9][9][2][null]',
	LENDER_OFFER: '[2][9][12][3][null]',
	BRANCH_REVIEW: '[2][9][12][10][2]',
	VENDOR_REVIEW: '[2][9][12][10][1]',
	// recently changes are made from the backend for credit review
	CREDIT_REVIEW: '[2][9][12][10][null]',
	CREDIT_REVIEW2: '[2][9][12][10][0]',
	SANCTIONS_DISBURSED: '[2][9][12][12][null]',
	BORROWER_REJECTION: '[2][9][12][11][null]',
	LENDER_REJECTION: '[2][9][14][7][null]',
	QUERY_RAISED_BY_NC: '[8][12][null][null][null]',
	DUPLICATE_CASE: '[15][15][null][null][null]',
	QUERY_RAISED_BY_CO: '[16][16][null][null][null]',
	REASSIGNED: '[2][9][10][4][null]',
	NOT_QUALIFIED: '[7][13][null][null][null]',
	EXPIRED: '[2][9][14][14][null]',
	DRAFT: '[1][1][0][null][null]',
	DRAFT2: '[1][1][null][null][null]',
	LOAN_EXPIRE: '[14][14][null][null][null]',
	BPO_INITIATED: '[2][7][null][null][null]',

	// TL_REVIEW:'[2][9][9][2][null]',
	// //CHECK FOR OTHER LOANS IN SAME TAB
	// TL_REVIEW2:'[2][9][12][10][null]',
	// ANALYST_REVIEW: '[2][9][12][10][null]',
};

// This consists of key names with '[status1],[status2],[status3],[status4],[status5],[status6]' and values with [view, edit, verification_details] based on the icons to display.
// Key names are extracted based on the NCSTATUSMANAGE Api :
const ICONS_FOR_LOAN_STATUS = {
	// APPLICATION
	[APPLICATION_STATUS_UNIQUE_ID.APPLICATION]: [
		ICON_NAMES.view,
		ICON_NAMES.comments,
		ICON_NAMES.upload,
		ICON_NAMES.edit,
		ICON_NAMES.on_site_verification,
		ICON_NAMES.verification,
		ICON_NAMES.initiate,
		ICON_NAMES.not_qualify,
		ICON_NAMES.external_evaluation,
		ICON_NAMES.document_status,
		ICON_NAMES.copy_to_clipboard,
		ICON_NAMES.integrations,
		ICON_NAMES.additional_verification,
	],
	// IN-PROGRESS
	[APPLICATION_STATUS_UNIQUE_ID.IN_PROGRESS]: [
		ICON_NAMES.view,
		ICON_NAMES.comments,
		ICON_NAMES.verification,
		ICON_NAMES.external_evaluation,
		ICON_NAMES.document_status,
		ICON_NAMES.integrations,
		ICON_NAMES.additional_verification,
	],
	// CREDIT ASSIGNED & TL REVIEW
	[APPLICATION_STATUS_UNIQUE_ID.CREDIT_ASSIGNED]: [
		ICON_NAMES.view,
		ICON_NAMES.comments,
		ICON_NAMES.upload,
		ICON_NAMES.download,
		ICON_NAMES.on_site_verification,
		ICON_NAMES.verification,
		ICON_NAMES.reject,
		ICON_NAMES.raise_query_to_branch, // new icon
		ICON_NAMES.external_evaluation,
		ICON_NAMES.document_status, // new icon
		// ICON_NAMES.assign_to_tl_icon,
		ICON_NAMES.assign_to_analyst_icon,
		ICON_NAMES.generate_pdf_icon,
		ICON_NAMES.e_registrations,
		ICON_NAMES.integrations,
		ICON_NAMES.risk_profiling,
		ICON_NAMES.cam_profiling,
		ICON_NAMES.additional_verification,
	],
	// LENDER OFFER : bcm login in credit assigned stage
	[APPLICATION_STATUS_UNIQUE_ID.LENDER_OFFER]: [
		ICON_NAMES.view,
		ICON_NAMES.comments,
		ICON_NAMES.download,
		ICON_NAMES.on_site_verification,
		ICON_NAMES.verification,
		ICON_NAMES.reject,
		ICON_NAMES.external_evaluation,
		ICON_NAMES.raise_query_to_branch,
		ICON_NAMES.document_status,
		ICON_NAMES.sanction_terms_conditions_icon,
		ICON_NAMES.e_registrations,
		ICON_NAMES.integrations,
		ICON_NAMES.risk_profiling,
		ICON_NAMES.cam_profiling,
		ICON_NAMES.additional_verification,
	],
	[APPLICATION_STATUS_UNIQUE_ID.BPO_INITIATED]: [
		ICON_NAMES.view,
		ICON_NAMES.comments,
		ICON_NAMES.verification,
		ICON_NAMES.document_status,
		ICON_NAMES.external_evaluation,
		ICON_NAMES.integrations,
		ICON_NAMES.additional_verification,
	],
	// BRANCH REVIEW
	[APPLICATION_STATUS_UNIQUE_ID.BRANCH_REVIEW]: [
		ICON_NAMES.view,
		ICON_NAMES.comments,
		ICON_NAMES.edit,
		ICON_NAMES.download,
		ICON_NAMES.on_site_verification,
		ICON_NAMES.verification,
		ICON_NAMES.move_to_credit_review, // new icon
		ICON_NAMES.external_evaluation,
		ICON_NAMES.document_status, // new icon
		ICON_NAMES.copy_to_clipboard,
		ICON_NAMES.sanction_terms_conditions_icon, // new icon
		ICON_NAMES.cam_profiling,
		ICON_NAMES.additional_verification,
	],

	// VENDOR REVIEW
	[APPLICATION_STATUS_UNIQUE_ID.VENDOR_REVIEW]: [
		ICON_NAMES.view,
		ICON_NAMES.comments,
		ICON_NAMES.download,
		ICON_NAMES.on_site_verification,
		ICON_NAMES.verification,
		ICON_NAMES.move_to_credit_review, // new icon
		ICON_NAMES.external_evaluation,
		ICON_NAMES.document_status,
		ICON_NAMES.cam_profiling,
		ICON_NAMES.additional_verification,
	],

	//CREDIT REVIEW & ANALYST REVIEW
	[APPLICATION_STATUS_UNIQUE_ID.CREDIT_REVIEW]: [
		ICON_NAMES.view,
		ICON_NAMES.comments,
		ICON_NAMES.upload,
		ICON_NAMES.download,
		ICON_NAMES.on_site_verification,
		ICON_NAMES.verification,
		ICON_NAMES.raise_query_to_branch, // advised by madhuri
		ICON_NAMES.external_evaluation,
		ICON_NAMES.document_status,
		ICON_NAMES.reject,
		ICON_NAMES.generate_word_icon,
		ICON_NAMES.assign_to_tl_icon,
		// ICON_NAMES.assign_to_analyst_icon,
		ICON_NAMES.generate_pdf_icon,
		ICON_NAMES.e_registrations,
		ICON_NAMES.cam_profiling,
		ICON_NAMES.additional_verification,
	],
	[APPLICATION_STATUS_UNIQUE_ID.CREDIT_REVIEW2]: [
		ICON_NAMES.view,
		ICON_NAMES.comments,
		ICON_NAMES.download,
		ICON_NAMES.on_site_verification,
		ICON_NAMES.verification,
		ICON_NAMES.raise_query_to_branch, // advised by madhuri
		ICON_NAMES.external_evaluation,
		ICON_NAMES.document_status,
		ICON_NAMES.reject,
		ICON_NAMES.generate_word_icon,
		ICON_NAMES.assign_to_tl_icon,
		// ICON_NAMES.assign_to_analyst_icon,
		ICON_NAMES.generate_pdf_icon,
		ICON_NAMES.e_registrations,
		ICON_NAMES.risk_profiling,
		ICON_NAMES.cam_profiling,
		ICON_NAMES.additional_verification,
	],
	//SANCTIONS / DISBURSED
	[APPLICATION_STATUS_UNIQUE_ID.SANCTIONS_DISBURSED]: [
		ICON_NAMES.view,
		ICON_NAMES.comments,
		ICON_NAMES.download,
		ICON_NAMES.upload,
		ICON_NAMES.on_site_verification,
		ICON_NAMES.verification,
		ICON_NAMES.external_evaluation,
		ICON_NAMES.document_status,
		ICON_NAMES.sanction_terms_conditions_icon, // new icon
		ICON_NAMES.e_registrations,
		ICON_NAMES.integrations,
		ICON_NAMES.risk_profiling,
		ICON_NAMES.cam_profiling,
		ICON_NAMES.additional_verification,
		ICON_NAMES.insurance_finalization,
	],
	// BORROWER REJECTION
	[APPLICATION_STATUS_UNIQUE_ID.BORROWER_REJECTION]: [
		ICON_NAMES.view,
		ICON_NAMES.comments,
		ICON_NAMES.restore,
		ICON_NAMES.document_status,
		ICON_NAMES.sanction_terms_conditions_icon,
		ICON_NAMES.risk_profiling,
	],

	// LENDER REJECTION
	[APPLICATION_STATUS_UNIQUE_ID.LENDER_REJECTION]: [
		ICON_NAMES.view,
		ICON_NAMES.comments,
		ICON_NAMES.download,
		ICON_NAMES.on_site_verification,
		ICON_NAMES.verification,
		ICON_NAMES.restore,
		ICON_NAMES.external_evaluation,

		ICON_NAMES.document_status,
		ICON_NAMES.sanction_terms_conditions_icon,
		ICON_NAMES.risk_profiling,
		ICON_NAMES.cam_profiling,
		ICON_NAMES.additional_verification,
	],

	// QUERY RAISED BY NC - Not required because it is the parent status and has multiple sub status's under it.
	// '[null],[null],[null],[null],[null],[null]': [
	// 	ICON_NAMES.view,
	// 	ICON_NAMES.comments,
	// 	ICON_NAMES.upload,
	// 	ICON_NAMES.edit,
	// 	ICON_NAMES.on_site_verification,
	// 	ICON_NAMES.initiate,
	// 	ICON_NAMES.not_qualify,
	// 	ICON_NAMES.external_evaluation,
	// 	ICON_NAMES.document_status,
	// ],

	// NO DOCUMENTS, MISSING DOCUMENTS & BLUR DOCUMENTS - comes under query raised stage
	[APPLICATION_STATUS_UNIQUE_ID.QUERY_RAISED_BY_NC]: [
		ICON_NAMES.view,
		ICON_NAMES.comments,
		ICON_NAMES.upload,
		ICON_NAMES.edit,
		ICON_NAMES.on_site_verification,
		ICON_NAMES.verification,
		ICON_NAMES.initiate,
		ICON_NAMES.not_qualify,
		ICON_NAMES.external_evaluation,
		ICON_NAMES.document_status,
		ICON_NAMES.copy_to_clipboard, // new icon
		ICON_NAMES.cam_profiling,
		ICON_NAMES.additional_verification,
	],
	[APPLICATION_STATUS_UNIQUE_ID.QUERY_RAISED_BY_CO]: [
		ICON_NAMES.view,
		ICON_NAMES.comments,
		ICON_NAMES.upload,
		ICON_NAMES.edit,
		// ICON_NAMES.on_site_verification,
		ICON_NAMES.verification,
		ICON_NAMES.initiate,
		ICON_NAMES.not_qualify,
		ICON_NAMES.external_evaluation,
		ICON_NAMES.document_status,
		// ICON_NAMES.copy_to_clipboard, // new icon
		ICON_NAMES.cam_profiling,
		ICON_NAMES.additional_verification,
	],
	// DUPLICATE CASE - comes under query raised stage
	[APPLICATION_STATUS_UNIQUE_ID.DUPLICATE_CASE]: [
		ICON_NAMES.view,
		ICON_NAMES.comments,
		ICON_NAMES.upload,
		ICON_NAMES.edit,
		ICON_NAMES.on_site_verification,
		ICON_NAMES.verification,
		ICON_NAMES.initiate,
		ICON_NAMES.not_qualify,
		ICON_NAMES.external_evaluation,
		ICON_NAMES.document_status,
		ICON_NAMES.copy_to_clipboard, // new icon
		ICON_NAMES.cam_profiling,
		ICON_NAMES.additional_verification,
	],

	// REASSIGNED
	[APPLICATION_STATUS_UNIQUE_ID.REASSIGNED]: [
		ICON_NAMES.view,
		ICON_NAMES.comments,
		ICON_NAMES.edit,
		ICON_NAMES.on_site_verification,
		ICON_NAMES.verification,
		ICON_NAMES.move_to_credit_assigned, // new
		ICON_NAMES.raise_query_to_branch, // new
		ICON_NAMES.external_evaluation,
		ICON_NAMES.document_status, // new
		ICON_NAMES.copy_to_clipboard,
		ICON_NAMES.sanction_terms_conditions_icon, // new icon
		ICON_NAMES.e_registrations,
		ICON_NAMES.risk_profiling,
		ICON_NAMES.cam_profiling,
		ICON_NAMES.additional_verification,
	],

	// NOT QUALIFIED
	[APPLICATION_STATUS_UNIQUE_ID.NOT_QUALIFIED]: [
		ICON_NAMES.view,
		ICON_NAMES.comments,
		ICON_NAMES.on_site_verification,
		ICON_NAMES.verification,
		ICON_NAMES.restore,
		ICON_NAMES.external_evaluation,
		ICON_NAMES.document_status, // new icon
		ICON_NAMES.cam_profiling,
		ICON_NAMES.additional_verification,
	],

	// EXPIRED
	[APPLICATION_STATUS_UNIQUE_ID.EXPIRED]: [ICON_NAMES.view],

	// REVIEW -  Not required because it is the parent status and has multiple sub status's under it.
	// '[null],[null],[null],[null],[null],[null]': [
	// 	ICON_NAMES.view,
	// 	ICON_NAMES.comments,
	// 	ICON_NAMES.upload,
	// 	ICON_NAMES.on_site_verification,
	// 	ICON_NAMES.verification,
	// 	ICON_NAMES.move_to_credit_review, // new icon
	// 	ICON_NAMES.external_evaluation,
	// 	ICON_NAMES.document_status,
	// ],

	// REJECTED / EXPIRED - Not required currently
	// '[null],[null],[null],[null],[null],[null]': [ICON_NAMES.view],

	// DRAFT
	[APPLICATION_STATUS_UNIQUE_ID.DRAFT]: [
		ICON_NAMES.edit,
		ICON_NAMES.comments,
		ICON_NAMES.not_qualify,
		ICON_NAMES.copy_to_clipboard, // new icon
	],
	// special scenario in draft stage when there's no loan bank mapping record found
	[APPLICATION_STATUS_UNIQUE_ID.DRAFT2]: [
		ICON_NAMES.edit,
		ICON_NAMES.comments,
		ICON_NAMES.not_qualify,
		ICON_NAMES.copy_to_clipboard, // new icon
	],

	// LOAN EXPIRE
	[APPLICATION_STATUS_UNIQUE_ID.LOAN_EXPIRE]: [ICON_NAMES.view],

	// TL REVIEW
	// [APPLICATION_STATUS_UNIQUE_ID.TL_REVIEW]: [ICON_NAMES.re_assign, ICON_NAMES.generate_pdf_icon],
	// [APPLICATION_STATUS_UNIQUE_ID.TL_REVIEW2]: [ICON_NAMES.re_assign, ICON_NAMES.generate_pdf_icon],

	// ANALYST REVIEW
	// [APPLICATION_STATUS_UNIQUE_ID.ANALYST_REVIEW]: [
	// 	ICON_NAMES.generate_word_icon,
	// 	ICON_NAMES.re_assign,
	// 	ICON_NAMES.generate_pdf_icon,
	// ],
};

export {
	API_END_POINT,
	COMMON_SERVICES_API_END_POINT,
	OTP_API_END_POINT,
	ENDPOINT_BANK,
	CLIENT_EMAIL_ID,
	CLIENT_VERIFY_URL,
	BANK_TOKEN_API,
	BANK_LIST_API,
	CUSTOMER_FETCH_API_END_POINT,
	BIOMETRIC_REDIRECT,
	NC_STATUS_CODE,
	WHITE_LABEL_URL,
	PRODUCT_LIST_URL,
	PRODUCT_DETAILS_URL,
	DOCS_UPLOAD_URL,
	BORROWER_UPLOAD_URL,
	GENERATE_OTP_URL,
	VERIFY_OTP_URL,
	CREATE_CASE,
	CREATE_CASE_OTHER_USER,
	USER_ROLES,
	SEARCH_BANK_BRANCH_LIST,
	SEARCH_LOAN_ASSET,
	CUB_ACCOUNT_MINI_STATEMENT,
	UPLOAD_CUB_STATEMENT,
	UPDATE_LOAN_ASSETS,
	FETCH_CIBIL_SCORE,
	PINCODE_ADRRESS_FETCH,
	APP_DOMAIN,
	ROC_DATA_FETCH,
	BUSSINESS_LOAN_CASE_CREATION,
	BUSSINESS_LOAN_CASE_CREATION_EDIT,
	WHITELABEL_ENCRYPTION_API,
	LOGIN_CREATEUSER,
	SEARCH_COMPANY_NAME,
	ADD_SUBSIDIARY_DETAILS,
	ADD_BANK_DETAILS,
	ADD_SHAREHOLDER_DETAILS,
	ADD_REFENCE_DETAILS,
	BANK_LIST_FETCH,
	DOCTYPES_FETCH,
	APP_CLIENT,
	SECRET,
	TEMPLATE_FETCH,
	DOCS_UPLOAD_URL_LOAN,
	REDIRECT_CREATE,
	CIN_UPDATE,
	ICON_PERMISSIONS,
	USER_TYPE,
	DELETE_DOCUMENT,
	VIEW_DOCUMENT,
	ICONS_FOR_LOAN_STATUS,
	ICON_NAMES,
	LOAN_ORIGIN_NC_ONBOARDING,
	LOAN_ORIGIN_UIUX,
	FETCH_LMS_DISB_STATUS,
	RETRIGGER_LMS_DISBURSEMENT,
	GET_BANK_DETAILS,
	TRIGGER_PENNY_DROP,
	PENNY_DROP_STATUS_FETCH,
	DIRECTOR_STATUS,
	DELETE_SANCTION_ASSEST_DATA,
	DELETE_SANCTION_ADDITIONAL_ASSEST_DATA,
	DELETE_CREDIT_LIMIT_DATA,
};
