import React, { Component } from 'react';
import { message } from 'antd';

export default function(ComposedComponent) {
	class NetworkDetector extends Component {
		state = {
			isDisconnected: false,
		};

		componentDidMount() {
			this.handleConnectionChange();
			window.addEventListener('online', this.handleConnectionChange);
			window.addEventListener('offline', this.handleConnectionChange);
		}

		componentWillUnmount() {
			window.removeEventListener('online', this.handleConnectionChange);
			window.removeEventListener('offline', this.handleConnectionChange);
		}

		handleConnectionChange = () => {
			const condition = navigator.onLine;
			if (condition) {
				const webPing = setInterval(() => {
					fetch('//google.com', {
						mode: 'no-cors',
					})
						.then(() => {
							if (this.state.isDisconnected === true) message.success('Back Online');

							this.setState({ isDisconnected: false }, () => {
								return clearInterval(webPing);
							});
						})
						.catch(() => this.setState({ isDisconnected: true }));
				}, 2000);
				return;
			}

			return this.setState({ isDisconnected: true });
		};

		render() {
			const { isDisconnected } = this.state;
			return <ComposedComponent {...this.props} isDisconnected={isDisconnected} />;
		}
	}

	return NetworkDetector;
}
