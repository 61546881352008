import { combineReducers } from 'redux';
import { createEntityReducer } from '../../shared/utils/helper/reduxUtility';
import {
	GET_LOAN_LIMIT,
	CREATE_APPROVAL_LIST,
	GET_APPROVAL_LIST,
	UPDATE_APPROVAL,
	GET_APPROVAL_USER_LIST,
	GET_FINAL_APPROVAL_STATUS,
} from './types';

const loanLimit = createEntityReducer(GET_LOAN_LIMIT);
const createResponse = createEntityReducer(CREATE_APPROVAL_LIST);
const approvalList = createEntityReducer(GET_APPROVAL_LIST);
const updateResponse = createEntityReducer(UPDATE_APPROVAL);
const approvalUserList = createEntityReducer(GET_APPROVAL_USER_LIST);
const finalApprovalStatus = createEntityReducer(GET_FINAL_APPROVAL_STATUS);

export const loanApproval = combineReducers({
	loanLimit,
	createResponse,
	approvalList,
	updateResponse,
	approvalUserList,
	finalApprovalStatus,
});
