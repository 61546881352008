import { fakeAuth } from './RouteHelper';
export const getWhiteLabel = () => {
	let backgroundColor;
	try {
		let whiteLabel = fakeAuth.getWhiteLabel();
		if (whiteLabel === null || whiteLabel === undefined) {
			backgroundColor = '#009f00'.concat(',', '#058405');
			return backgroundColor;
		}
		backgroundColor = whiteLabel.buttonColor1.concat(',', whiteLabel.buttonColor2);
		return backgroundColor;
	} catch (error) {
		backgroundColor = '#009f00'.concat(',', '#058405');
		return backgroundColor;
	}
};

export const buttonTextColor = () => {
	try {
		let buttonTextColor = fakeAuth.getWhiteLabel().buttonTextColor;
		if (buttonTextColor === null || buttonTextColor === undefined) {
			return '#ffffff';
		}
		return buttonTextColor;
	} catch (error) {
		return '#ffffff';
	}
};

export const getHeaderThemeColor = () => {
	let headerThemeColor;
	try {
		headerThemeColor = fakeAuth.getWhiteLabel();
		headerThemeColor.text_main_header_color = headerThemeColor.text_main_header_color || 'white';
		headerThemeColor.text_underline_main_header_color = headerThemeColor.text_underline_main_header_color || 'white';
		if (headerThemeColor === null || headerThemeColor === undefined) {
			headerThemeColor = {
				themeColor2: '#e8f1f7',
				themeColor1: '#5c79b5',
			};
			return headerThemeColor;
		}
		return headerThemeColor;
	} catch (error) {
		headerThemeColor = {
			themeColor2: '#e8f1f7',
			themeColor1: '#5c79b5',
		};
		return headerThemeColor;
	}
};

export const secret = 'htde6458dgej2164';
