import CryptoJS from 'crypto-js';

import { SECRET } from '../_config/app.config';

export const encryptReq = reqBody => {
	try {
		return CryptoJS.AES.encrypt(JSON.stringify(reqBody), SECRET).toString();
	} catch (err) {
		// ('error-encryptReq-', err);
		return err;
	}
};

export const decryptRes = reqBody => {
	try {
		const reqBytes = CryptoJS.AES.decrypt(reqBody, SECRET),
			reqStr = reqBytes.toString(CryptoJS.enc.Utf8);
		return JSON.parse(reqStr);
	} catch (err) {
		// ('error-api-decrypt-req-', err);
		return err;
	}
};

export const decryptViewDocumentUrl = url => {
	const rawData = CryptoJS.enc.Base64.parse(url);
	const key = CryptoJS.enc.Latin1.parse(SECRET);
	const iv = CryptoJS.enc.Latin1.parse(SECRET);
	const plaintextData = CryptoJS.AES.decrypt({ ciphertext: rawData }, key, {
		iv: iv,
	});
	return plaintextData.toString(CryptoJS.enc.Latin1);
};

// Encode String to Base64
export const encryptBase64 = value => {
	var words = CryptoJS.enc.Utf8.parse(value); // WordArray object
	var base64 = CryptoJS.enc.Base64.stringify(words); // string: 'SGVsbG8gd29ybGQ='
	return base64.toString();
};

// Decode String from Base64 Enconding
export const decryptBase64 = encodedValue => {
	var words = CryptoJS.enc.Base64.parse(encodedValue);
	var textString = CryptoJS.enc.Utf8.stringify(words);
	return textString.toString();
};

export function encryptSessionID(sessionID, key) {
	const iv = CryptoJS.lib.WordArray.random(16); // Generate a random IV
	const encrypted = CryptoJS.AES.encrypt(sessionID, key, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	});
	const encryptedWithIV = iv.concat(encrypted.ciphertext); // Append IV to the ciphertext
	return encodeURIComponent(encryptedWithIV.toString(CryptoJS.enc.Base64));
}

export const decryptSessionId = encodedPasswordBase64 => {
	const SECRET = process.env.REACT_APP_SESSION_ID_SECRET;

	// Convert the secret key to binary
	const key = CryptoJS.enc.Utf8.parse(SECRET);

	const decodedEncryptedSessionID = decodeURIComponent(encodedPasswordBase64);
	const encryptedWithIV = CryptoJS.enc.Base64.parse(decodedEncryptedSessionID);
	const iv = encryptedWithIV.clone(); // Extract IV from the ciphertext
	iv.sigBytes = 16;
	iv.clamp(); // Adjust IV length
	const ciphertext = encryptedWithIV.clone(); // Extract ciphertext
	ciphertext.words.splice(0, 4); // Remove IV from the ciphertext
	ciphertext.sigBytes -= 16;
	const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	});
	return decrypted.toString(CryptoJS.enc.Utf8);
};

export const encryptString = data => {
	const key = CryptoJS.enc.Utf8.parse(SECRET);
	const iv = CryptoJS.lib.WordArray.random(16); // Random IV for each encryption

	// Use CBC mode with PKCS7 padding
	const encrypted = CryptoJS.AES.encrypt(data, key, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	});

	if (!encrypted) return;

	// Concatenate IV and ciphertext for storage/transmission
	const ciphertextWithIV = iv.concat(encrypted.ciphertext);

	// Return the encrypted data as Base64 string
	return ciphertextWithIV.toString(CryptoJS.enc.Base64);
};

export const decryptString = data => {
	const key = CryptoJS.enc.Utf8.parse(SECRET);

	// Convert the Base64 string back to a WordArray
	const ciphertextWithIV = CryptoJS.enc.Base64.parse(data);

	// Split IV and ciphertext
	const iv = ciphertextWithIV.clone();
	iv.sigBytes = 16;
	iv.clamp();

	const ciphertext = ciphertextWithIV.clone();
	ciphertext.words.splice(0, 4); // 4 Words == 16 bytes
	ciphertext.sigBytes -= 16;

	// Decrypt using CBC mode and PKCS7 padding
	const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
		padding: CryptoJS.pad.Pkcs7,
	});

	return decrypted.toString(CryptoJS.enc.Utf8);
};
