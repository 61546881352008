import React, { Component } from 'react';
import { Form, Icon, Input, Button, Select } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import './index.scss';
import { UserImage, Email, Mobile, Pincode } from '../../shared/constants/signinImage';
import { getCityState, CPType } from '../../redux/actions/index';
// import { CPType } from '../../shared/constants/signup';

const Option = Select.Option;
const { TextArea } = Input;

class Signin extends Component {
	state = {
		isLoading: false,
		cityState: {},
		isfetchingCity: false,
	};
	componentDidMount() {
		this.getCPType();
	}
	onLogin = e => {
		e.preventDefault();
		this.setState({
			isLoading: true,
		});
		this.props.form.validateFields((err, values) => {
			if (!err) {
				// this.onLoginApi(values);
				// console.log(values);
			} else {
				this.setState({
					isLoading: false,
				});
			}
		});
	};

	getCPType = () => {
		this.props.CPType();
	};
	onPincodeChange = e => {
		if (e.target.value.length === 6) {
			this.props.form.resetFields(['locality', 'city', 'state']);
			this.setState({
				isfetchingCity: true,
			});
			this.props.getCityState(e.target.value).then(res => this.setState({ cityState: res, isfetchingCity: false }));
		}
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		const { isLoading, cityState, isfetchingCity } = this.state;
		return (
			<div className='signin-wrapper'>
				<div>
					<Form onSubmit={this.onLogin} className='login-form'>
						<Form.Item>
							<div className='signin-title'>Your Full Name</div>
							{getFieldDecorator('name', {
								rules: [{ required: true, message: 'Please input your name!' }],
							})(<Input className='login-input' addonBefore={<Icon component={UserImage} />} placeholder='name' />)}
						</Form.Item>
						<Form.Item style={{ marginTop: '1rem' }}>
							<div className='signin-title'>Contact Details</div>
							{getFieldDecorator('emailid', {
								rules: [
									{ required: true, message: 'Please input your email id!' },
									{
										type: 'email',
										message: 'The Input is not valid E-mail',
									},
								],
							})(<Input className='login-input' addonBefore={<Icon component={Email} />} placeholder='email id' />)}
						</Form.Item>
						<Form.Item>
							{getFieldDecorator('mobilenumber', {
								rules: [
									{
										pattern: /^(\+\d{1,5}[- ]?)?\d{10}$/,
										message: 'Please Enter Valid Mobile Number',
									},
									{ required: true, message: 'Please input your mobile number!' },
								],
							})(
								<Input
									className='login-input'
									addonBefore={<Icon component={Mobile} />}
									placeholder='10 digit mobile number'
								/>
							)}
						</Form.Item>
						<Form.Item>
							{getFieldDecorator('pincode', {
								rules: [
									{
										pattern: /^\d{6}$/,
										message: 'Please Enter Valid Pincode',
									},
									{ required: true, message: 'Please input your pincode!' },
								],
							})(
								<Input
									className='login-input'
									addonBefore={<Icon component={Pincode} />}
									placeholder='PIN CODE'
									onChange={this.onPincodeChange}
								/>
							)}
						</Form.Item>
						<Form.Item>
							{getFieldDecorator('locality', {
								rules: [{ required: true, message: 'Please input your Location!' }],
							})(
								<Select className='login-input' allowClear loading={isfetchingCity} placeholder='Location'>
									{cityState &&
										cityState.locality &&
										cityState.locality.map((item, index) => {
											return (
												<Option key={index} value={item}>
													{item}
												</Option>
											);
										})}
								</Select>
							)}
						</Form.Item>

						<Form.Item>
							{getFieldDecorator('city', {
								rules: [{ required: true, message: 'Please input your City!' }],
							})(
								<Select className='login-input' loading={isfetchingCity} placeholder='City'>
									{cityState &&
										cityState.district &&
										cityState.district.map((item, index) => {
											return (
												<Option key={index} value={item}>
													{item}
												</Option>
											);
										})}
								</Select>
							)}
						</Form.Item>

						<Form.Item>
							{getFieldDecorator('state', {
								rules: [{ required: true, message: 'Please input your State!' }],
							})(
								<Select className='login-input' loading={isfetchingCity} placeholder='State'>
									{cityState &&
										cityState.state &&
										cityState.state.map((item, index) => {
											return (
												<Option key={index} value={item}>
													{item}
												</Option>
											);
										})}
								</Select>
							)}
						</Form.Item>

						<Form.Item>
							{getFieldDecorator('address')(<TextArea className='login-input' placeholder='Address' autosize />)}
						</Form.Item>

						{/* <Form.Item>
							{getFieldDecorator('cpType', {
								rules: [{ required: true, message: 'Please input your CP Type!' }]
							})(
								<Select className='login-input' placeholder='CP Type'>
									{CPType &&
										CPType.map((item, index) => {
											return (
												<Option key={index} value={item}>
													{item}
												</Option>
											);
										})}
								</Select>
							)}
						</Form.Item> */}

						<Form.Item>
							<Button loading={isLoading} type='primary' onClick={this.onLogin} className='signin-form-button'>
								<span className='login-form-button-text'>SIGNUP</span>
							</Button>
						</Form.Item>
						<div onClick={() => this.props.history.push('')} className='registered-user-button'>
							Already a registered User?
						</div>
					</Form>
				</div>
			</div>
		);
	}
}

function matchDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			getCityState,
			CPType,
		},
		dispatch
	);
}

export default Form.create()(withRouter(connect(null, matchDispatchToProps)(Signin)));
