import React, { useState, useEffect } from 'react';
import Lottie from 'lottie-react-web';

//import NCLogo from '../../constants/NCLogo.json';
import DefaultLogo from '../../constants/generic_loader_1.json';
import { fakeAuth } from '../../../shared/utils/helper/RouteHelper';

export default function NCLoader(props) {
	const { width } = props;
	const [loaders, setLoaders] = useState(DefaultLogo);
	useEffect(() => {
		let loaderPermission = DefaultLogo;
		try {
			loaderPermission = DefaultLogo;
			if (fakeAuth.getWhiteLabel()) {
				loaderPermission = fakeAuth.getWhiteLabel().loader_json;
			}
			fetch(loaderPermission)
				.then(response => response.json())
				.then(json => setLoaders(json));
		} catch (error) {
			console.error('Loading-loaderpermission-', error);
		}
		// eslint-disable-next-line
	}, []);
	return (
		<Lottie
			options={{
				animationData: loaders,
			}}
			width={width ? width : 500}
		/>
	);
}
